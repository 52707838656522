import AuthController from '../AuthProvider/AuthController';

class CareerService {
  async getCareerInfoBasic(id) {
    const token = await AuthController.getToken();

    let result = await fetch(
      process.env.REACT_APP_API_URL + "/api/career/" + id,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    );

    let responseCode = result.status;

    if (responseCode >= 400) {
      result = {};
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }
  //
  // Scrap Languages
  //
  async scrapLanguages(data, code, outputLanguage) {
    const token = await AuthController.getToken();

    let result = await fetch(
      process.env.REACT_APP_API_URL + "/api/languages?code=" + code + "&country=" + outputLanguage,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        method: "POST",
        body: data,
      }
    );
    let responseCode = result.status;
    if (responseCode >= 400) {
      result = {};
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }
  //
  //  Update Languages
  //
  async updateLanguages(model) {
    const token = await AuthController.getToken();

    let result = await fetch(process.env.REACT_APP_API_URL + "/api/career", {
      headers: {
        Authorization: "Bearer " + token.idToken.rawIdToken,
        "Content-Type": "application/json",
      },
      method: "PATCH",
      body: JSON.stringify(model),
    });

    let responseCode = result.status;
    if (responseCode >= 400) {
      result = {};
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }
  //
  // Get languages
  //
  async getLanguages(careerId, outputLanguage) {
    const token = await AuthController.getToken();
    let result = await fetch(
      process.env.REACT_APP_API_URL +
        "/api/languages?careerid=" +
        careerId +
        "&country=" +
        outputLanguage,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        method: "POST",
      }
    );
    let responseCode = result.status;
    if (responseCode >= 400) {
      result = {};
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }
  //
  //  Scrap  Skills
  //
  async scrapSkills(model, code) {
    const token = await AuthController.getToken();

    let result = await fetch(
      process.env.REACT_APP_API_URL + "/api/skills?code=" + code,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        method: "POST",
        body: model,
      }
    );
    let responseCode = result.status;
    if (responseCode >= 400) {
      result = {};
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }

  //
  // Send Skills
  //
  async sendSkills(model) {
    const token = await AuthController.getToken();
    let result = await fetch(process.env.REACT_APP_API_URL + "/api/career", {
      headers: {
        Authorization: "Bearer " + token.idToken.rawIdToken,
        "Content-Type": "application/json",
      },
      method: "PATCH",
      body: JSON.stringify(model),
    });
    let responseCode = result.status;
    if (responseCode >= 400) {
      result = {};
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }
  //
  // Get Skills
  //
  async getSkills(careerId, outputLanguage) {
    const token = await AuthController.getToken();
    let result = await fetch(
      process.env.REACT_APP_API_URL +
        "/api/skills?careerid=" +
        careerId +
        "&country=" +
        outputLanguage,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        method: "POST",
      }
    );
    let responseCode = result.status;
    if (responseCode >= 400) {
      result = {};
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }
  //
  // UPDATE hobbies
  //
  async sendHobbies(model) {
    const token = await AuthController.getToken();
    let result = await fetch(process.env.REACT_APP_API_URL + "/api/career", {
      headers: {
        Authorization: "Bearer " + token.idToken.rawIdToken,
        "Content-Type": "application/json",
      },
      method: "PATCH",
      body: JSON.stringify(model),
    });

    let responseCode = result.status;
    if (responseCode >= 400) {
      result = {};
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }
  //
  // Scrap Hobbies
  //
  async scrapHobbies(model, code) {
    const token = await AuthController.getToken();
    let result = await fetch(
      process.env.REACT_APP_API_URL + "/api/hobbies?code=" + code,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        method: "POST",
        body: model,
      }
    );
    let responseCode = result.status;
    if (responseCode >= 400) {
      result = {};
    } else {
      result = await result.json();
    }

    return { result, responseCode };
  }
  //
  // GET Hobbies
  //
  async getHobbies(careerId) {
    const token = await AuthController.getToken();
    let result = await fetch(
      process.env.REACT_APP_API_URL + "/api/hobbies?careerid=" + careerId,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        method: "POST",
      }
    );
    let responseCode = result.status;
    if (responseCode >= 400) {
      result = {};
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }

  //
  // GET Countries Options
  //
  async getCountries(outputLanguage) {
    const token = await AuthController.getToken();
    let result = await fetch(
      process.env.REACT_APP_API_URL + "/api/options/countries/"+outputLanguage,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        method: "GET",
      }
    );

    let responseCode = result.status;
    if (responseCode >= 400) {
      result = {};
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }

  //
  // GET Business Sector Options
  //
  async getBusinessSectorOptions(outputLanguage) {
    const token = await AuthController.getToken();
    let result = await fetch(
      process.env.REACT_APP_API_URL +
        "/api/options/business-sector?country=" +
        outputLanguage,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        method: "GET",
      }
    );

    let responseCode = result.status;
    if (responseCode >= 400) {
      result = {};
    } else {
      result = await result.json();
    }

    return { result, responseCode };
  }

  //
  // GET Languages Options
  //
  async getLanguagesOptions(outputLanguage) {
    const token = await AuthController.getToken();
    let result = await fetch(
      process.env.REACT_APP_API_URL +
        "/api/options/languages?country=" +
        outputLanguage,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        method: "GET",
      }
    );

    let responseCode = result.status;
    if (responseCode >= 400) {
      result = {};
    } else {
      result = await result.json();
    }

    return { result, responseCode };
  }
}

export default new CareerService();
