import {
  LANGUAGES_SET_FORM_DATA,
  LANGUAGES_SET_SCRAPED_DATA,
} from "../Actions/Actions";

const initialState = {
  Languages: [{ Language: "", Level: "B1" }],
};

const LanguagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGES_SET_FORM_DATA: {
      return {
        ...state,
        Languages: action.payload.data,
      };
    }
    case LANGUAGES_SET_SCRAPED_DATA: {
      return {
        ...state,
        Languages: action.payload.data,
      };
    }
    default:
      return state;
  }
};

export default LanguagesReducer;
