class PowerAutomateSerivce {
  //
  // POST - Generate Word and Pdf files with PowerAutomate
  //
  async generateBertekFiles(body) {
    let result = await fetch(process.env.REACT_APP_POWER_AUTOMATE, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(body),
    });
    let responseCode = result.status;
    if (responseCode >= 400) {
      result = null;
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }
}

module.exports = new PowerAutomateSerivce();
