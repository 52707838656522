import React from "react";
import Radio from "@material-ui/core/Radio";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const GreenRadio = withStyles({
  root: {
    "&$checked": {
      color: "#2ecc71",
    },
  },
  checked: {},
})((props) => <Radio {...props} />);

const RadioButton = ({ checked, handleChange, value, name }) => {
  return (
    <GreenRadio
      name={name}
      checked={checked}
      onChange={handleChange}
      value={value}
    />
  );
};

RadioButton.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};

export default RadioButton;
