import { ERROR_REMOVE, ERROR_SET } from "../Actions/Actions";

const initialValue = {
  errors: [],
};

export default (state = initialValue, action) => {
  switch (action.type) {
    case ERROR_SET:
      return {
        ...state,
        errors: action.payload.data,
      };
    case ERROR_REMOVE:
      return {
        ...state,
        errors: [],
      };
    default:
      return state;
  }
};
