import React, { useRef } from "react";
import styled from "styled-components";
import { FiPaperclip } from "react-icons/fi";
import PropTypes from "prop-types";

const Wraper = styled.div`
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid ${({ error }) => (error ? "red" : "black")};
  font-size: 18px;
  width: 195px;
  height: 20px;
  opacity: ${({ value }) => (value ? 1 : 0.7)};

  &:hover {
    opacity: 1;
  }
`;

const Icon = styled(FiPaperclip)`
  font-size: 18px;
  margin-right: 5px;
  color: ${({ isError }) => (isError ? "red" : "black")};
`;
const Input = styled.input`
  display: none;
`;
const Text = styled.div`
  color: ${({ isError }) => (isError ? "red" : "black")};
  white-space: nowrap;
`;
const WhiteIcon = styled(Icon)`
  font-size: 18px;
  margin-right: 5px;
  color: white;
`;

const FileInput = ({ handleChange, value, error, disabled }) => {
  const hiddenInputRef = useRef(null);
  const handleClick = () => {
    hiddenInputRef.current.click();
  };
  return (
    <Wraper disabled={disabled} value={value} onClick={handleClick}>
      {" "}
      {!value ? <Icon /> : <WhiteIcon />}
      <Text> {value ? value : "Upload CV"}</Text>{" "}
      <Input
        disabled={disabled}
        ref={hiddenInputRef}
        onChange={handleChange}
        type="file"
      />
    </Wraper>
  );
};

FileInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
};

export default FileInput;
