import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BsTrash, BsPlus } from "react-icons/bs";
const Wraper = styled.div`
  height: 20px;
  width: ${({ width }) => (width ? width : 140)}px;
  display: flex;
  flex-wrap: nowrap;
  margin-left: auto;
  align-content: flex-end;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : 0)}px;
  &:hover {
    cursor: pointer;
  }
  margin-right: 10px;
`;
const Text = styled.div`
  margin-left: 5px;
  font-size: 15px;
  text-align: center;
`;
const TrashIcon = styled(BsTrash)`
  font-size: 18px;
  margin-left: auto;
`;
const PlusIcon = styled(BsPlus)`
  font-size: 18px;
  margin-left: auto;
`;

const IconButton = ({ text, Bin, Plus, handleClick, width, marginRight }) => (
  <Wraper width={width} onClick={handleClick} marginRight={marginRight}>
    {Bin && <TrashIcon />}
    {Plus && <PlusIcon />}
    <Text>{text}</Text>
  </Wraper>
);

IconButton.propTypes = {
  text: PropTypes.string.isRequired,
  Bin: PropTypes.bool,
  Plus: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  width: PropTypes.number,
  marginRight: PropTypes.number,
};

export default IconButton;
