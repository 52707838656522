import {
  SPINNER_START,
  SPINNER_STOP,
  SPINNER_ERROR_SET_MESSAGE,
} from "../Actions/Actions";

const initialState = {
  isSpinnerOn: false,
  message: "",
};

const spinner = (state = initialState, action) => {
  switch (action.type) {
    case SPINNER_START: {
      return {
        ...state,
        isSpinnerOn: true,
      };
    }
    case SPINNER_STOP: {
      return {
        ...state,
        isSpinnerOn: false,
      };
    }
    case SPINNER_ERROR_SET_MESSAGE: {
      return {
        ...state,
        message: action.payload.data,
      };
    }
    default:
      return state;
  }
};

export default spinner;
