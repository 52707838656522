import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";

import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}></IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({ isOpen, messageToDisplay, fromStep, goToStep, setDialog }) {
  const [open, setOpen] = React.useState(isOpen);
  const [message, setMessage] = React.useState(messageToDisplay ? messageToDisplay : "There is already data for this career. You can go to Update operation or stay here.");
  const [from, setFrom ] = React.useState(fromStep ? fromStep : "import");
  const [goTo, setGoTo] = React.useState(goToStep ? goToStep : "update");

  const handleClose = () => {
    setOpen(false);
    if(setDialog) { setDialog(false); }
  };
  useEffect(() => {
    setOpen(isOpen);
    setMessage(messageToDisplay ? messageToDisplay : "There is already data for this career. You can go to Update operation or stay here.");
    setFrom(fromStep ? fromStep : "import");
    setGoTo(goToStep ? goToStep : "update");
  }, [isOpen, messageToDisplay, fromStep, goToStep]);

  const handleUpdateStep = () => {
    const link = window.location.href;
    const newLink = link.replace(from, goTo);
    window.location.href = newLink;
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          style={{ color: "#2ECC71" }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Upsss..
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom style={{ textAlign: "center" }}>
            {message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "#2ECC71" }} onClick={handleUpdateStep}>
            Go to {goTo}
          </Button>
          {<Button style={{ color: "#2ECC71" }} onClick={handleClose}>
            Close dialog
          </Button>}
          
        </DialogActions>
      </Dialog>
    </div>
  );
}
