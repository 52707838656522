/**
 *
 * authController.ts
 *
 * Desc : To get token and refresh session if it's expired
 *
 * Author : Sebastien Hoarau
 *
 * Project : cocomilk
 *
 */


import { authProvider } from './authProvider';


class AuthController {

    /**
     * Get Token in session storage
     * @returns 
     */
    async getToken() {
        try {
            let token = await authProvider.getIdToken();
            if(token.idToken.expiration*1000 < Date.now()) {
                await this.refreshToken();
            }
            token = await authProvider.getIdToken();
            return token;
        } catch (error) {
            throw error;
        }
    }

    /**
     * Refresh token with the same scopes id
     * @returns 
     */
    async refreshToken() {
        try {
            const token = await authProvider.acquireTokenSilent({
                scopes: [(await authProvider.getIdToken()).idToken.claims.aud],
                account: authProvider.getAllAccounts()[0],
            });

            return token;
        } catch (error) {
            throw error;
        }
    }
}

export default new AuthController();