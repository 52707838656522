import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  moveToNextStep,
  setLanguagesScrapedData,
  setIsLanguagesScraped,
  spinnerStart,
  spinnerStop,
  setErrors,
  removeErrors,
  setLanguagesFormData,
} from "../../../Redux/Actions/Actions";

//components
import Stepper from "../../ReusableComponents/Stepper/stepper";
import Button from "../../ReusableComponents/Button/button";
import PdfDocument from "../../ReusableComponents/PdfComponent/PdfDocument";
import StepHeader from "../../ReusableComponents/StepHeader/StepHeader";
import LanguagesForm from "../../ReusableComponents/LanguagesForm/LanguagesForm";
import IconButton from "../../ReusableComponents/IconButton/iconButton";

import {
  FormWraper,
  ButtonWraper,
  Cv,
  FormFields,
  StyleForm,
  Wraper,
  Dots,
} from "./StyledComponents";

//Formik
import { Formik, Form, FieldArray } from "formik";

//Service
import CareerService from "../../../Services/CareerService";
import { useSnackbar } from "notistack";

const Languages = ({
  file,
  Languages,
  setLanguagesScrapedData,
  moveToNextStep,
  setIsLanguagesScraped,
  isLanguagesScraped,
  careerId,
  careerName,
  careerLastName,
  cvLanguage,
  cvTimeStamp,
  spinnerStart,
  spinnerStop,
  fileFull,
  setLanguagesFormData,
  errors,
  setErrors,
  removeErrors,
  stepType,
  outputLanguage,
}) => {
  const [fullWidthForm, setFullWidthForm] = useState(false);
  const [initialValues, setInitialValues] = useState({ Languages });
  const [localErrors, setLocalErrors] = useState([]);
  const [showErrors, setShowErrors] = useState(true);
  const [languageOptions, setLanguageOptions] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  //
  // Get Languages Options
  //
  useEffect(() => {
    async function getLanguages() {
      const { result, responseCode } = await CareerService.getLanguagesOptions(
        outputLanguage
      );
      if (responseCode >= 400) {
        enqueueSnackbar("Get Languages Options - error", {
          variant: "error",
        });
      } else {
        setLanguageOptions(result);
      }
    }
    getLanguages();
  }, [enqueueSnackbar, setLanguageOptions, outputLanguage]);

  useEffect(() => {
    async function getData() {
      if (stepType === "updateStep" && isLanguagesScraped === false) {
        spinnerStart();
        //get languages
        let languages = await CareerService.getLanguages(
          careerId,
          outputLanguage
        );

        if (languages.responseCode >= 400) {
          //manage error
          enqueueSnackbar("Get Languages - error", {
            variant: "error",
          });
        } else {
          languages = languages.result;

          if (languages) {
            languages = languages.map((item) => {
              return {
                Language: item.split(" ")[0],
                Level: item.split(" ")[1],
              };
            });
          }

          setInitialValues({ Languages: languages });
        }
        spinnerStop();
      }
    }
    getData();
  }, [
    careerId,
    enqueueSnackbar,
    stepType,
    spinnerStop,
    spinnerStart,
    isLanguagesScraped,
    outputLanguage,
  ]);

  //Scrap Languages
  useEffect(() => {
    document.title = "Languages";

    const scrapData = async () => {
      spinnerStart();
      if(!isLanguagesScraped) {
        //scrap
        const fileFormatType = fileFull.type;
        let fileType = "";
        if (fileFormatType === "application/pdf") {
          fileType = ".pdf";
        } else {
          fileType = ".docx";
        }

        const code = cvTimeStamp;
        const language = cvLanguage.toLowerCase();

        const data = new FormData();
        data.append("type", language);
        data.append("extension", fileType);
        data.append("code", code);
        const { result, responseCode } = await CareerService.scrapLanguages(
          data,
          code,
          outputLanguage,
        );
        if (responseCode >= 400) {
          enqueueSnackbar("Scrap languages - error", {
            variant: "error",
          });
        } else {
          let languages = [];
          languages = result;

          if (languages && languages.length > 0) {
            languages = languages.map((item) => {
              return {
                Language: item.split(" ")[0],
                Level: item.split(" ")[1],
              };
            });
            setInitialValues({ Languages: languages })
          }
        }
      }
      spinnerStop();
    };
    if(stepType === "importStep") {
      scrapData()
      setIsLanguagesScraped(true);
    }
  }, [
    stepType,
    setIsLanguagesScraped,
    enqueueSnackbar,
    errors,
    cvTimeStamp,
    fileFull,
    outputLanguage,
    isLanguagesScraped,
    careerId,
    file,
    setLanguagesScrapedData,
    cvLanguage,
    spinnerStop,
    spinnerStart,
  ]);

  //Set errors
  useEffect(() => {
    setLocalErrors(errors);
  }, [errors]);

  //Show errors
  useEffect(() => {
    document.title = "Languages";
    if (showErrors) {
      for (let i = 0; i < localErrors.length; i++) {
        enqueueSnackbar(localErrors[i], {
          variant: "error",
        });
      }
    }
    setShowErrors(false);
  }, [localErrors, enqueueSnackbar, showErrors]);

  const dotsClick = () => {
    setFullWidthForm(!fullWidthForm);
  };
  const stepperClicked = (values) => {
    setLanguagesFormData(values.Languages);
    setIsLanguagesScraped(true);
  };

  return (
    <Wraper>
      <StyleForm fullWidthForm={fullWidthForm}>
        <FormFields>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={async (values, { setSubmitting }) => {
              removeErrors();
              setLanguagesFormData(values.Languages);

              if (values.Languages && values.Languages.length >= 0) {
                spinnerStart();

                setSubmitting(true);
                let languages = [];
                let languagesDisplayed = [];
                
                for (let i = 0; i < values.Languages.length; i++) {
                  if (Languages[i] !== null) {
                    if(values.Languages[i].Language !== '') {
                      languages.push(
                        `${values.Languages[i].Language} ${values.Languages[i].Level}`
                      );
                      languagesDisplayed.push({
                        Language: values.Languages[i].Language,
                        Level: values.Languages[i].Level,
                      });
                    }
                  }
                }
                setLanguagesFormData(languagesDisplayed)

                const model = {
                  languages,
                  careerId,
                  type: "LANGUAGES",
                };
                const { responseCode } = await CareerService.updateLanguages(
                  model
                );

                if (responseCode >= 400) {
                  const errorArray = [];
                  errorArray.push("Add Languages - Error");
                  setErrors(errorArray);
                }

                setSubmitting(false);
                spinnerStop();
              }
              moveToNextStep(5);
            }}
          >
            {({ values, handleChange, setFieldValue }) => (
              <>
                <Stepper
                  StepNumber={2}
                  onClick={() => stepperClicked(values)}
                  isUpdate={stepType === "updateStep"}
                />
                <StepHeader>
                  Here are the informations concerning your candidate languages
                  we had on the CV
                </StepHeader>
                <Form>
                  <FormWraper>
                    <FieldArray name="Languages">
                      {({ push, remove }) => (
                        <>
                          {values.Languages &&
                            values.Languages.map((item, index) => (
                              <LanguagesForm
                                key={index}
                                remove={remove}
                                handleChange={handleChange}
                                language={item.Language}
                                languageLevel={item.Level}
                                number={index}
                                languages={languageOptions}
                                setFieldValue={setFieldValue}
                              />
                            ))}
                          <ButtonWraper>
                            <IconButton
                              style={{ width: "200px" }}
                              Plus
                              text={"Add an language"}
                              marginRight={10}
                              handleClick={() =>
                                push({
                                  Language: "",
                                  Level: "B1",
                                })
                              }
                            />
                          </ButtonWraper>
                        </>
                      )}
                    </FieldArray>

                    <ButtonWraper>
                      <Button
                        height={30}
                        width={120}
                        text={"Previous"}
                        submit={true}
                        fontSize={16}
                        marginLeft={30}
                        handleClick={(event) => {
                          event.preventDefault();
                          setIsLanguagesScraped(true);
                          setLanguagesFormData(values.Languages);
                          moveToNextStep(3);
                        }}
                      />
                      <Button
                        height={30}
                        width={120}
                        text={"Next"}
                        submit={true}
                        left
                        fontSize={16}
                        marginRight={0}
                      />
                    </ButtonWraper>
                  </FormWraper>
                </Form>
              </>
            )}
          </Formik>
        </FormFields>

        {stepType === "importStep" && <Dots onClick={dotsClick} />}
      </StyleForm>

      {!fullWidthForm && stepType === "importStep" && (
        <Cv>
          <PdfDocument file={file} />
        </Cv>
      )}
    </Wraper>
  );
};

const mapStateToProps = (state) => {
  return {
    stepType: state.Stepper.stepType,
    errors: state.Errors.errors,
    ScrapedData: state.CvGenerator.scrapedData,
    file: state.CvGenerator.cvFile,
    fileFull: state.CvGenerator.cvFilePdf,
    Languages: state.Languages.Languages,
    isLanguagesScraped: state.Stepper.isLanguagesScraped,
    careerId: state.Career.careerId,
    careerName: state.Career.firstName,
    careerLastName: state.Career.lastName,
    cvLanguage: state.CvGenerator.cvLanguage,
    cvTimeStamp: state.CvGenerator.cvTimeStamp,
    outputLanguage: state.CvGenerator.cvOutputLanguage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setErrors: (data) => dispatch(setErrors(data)),
    removeErrors: () => dispatch(removeErrors()),
    moveToNextStep: (data) => dispatch(moveToNextStep(data)),
    setLanguagesScrapedData: (data) => dispatch(setLanguagesScrapedData(data)),
    setIsLanguagesScraped: (data) => dispatch(setIsLanguagesScraped(data)),
    setLanguagesFormData: (data) => dispatch(setLanguagesFormData(data)),
    spinnerStart: () => dispatch(spinnerStart()),
    spinnerStop: () => dispatch(spinnerStop()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Languages);
