export const SPINNER_START = "SPINNER_START";
export const SPINNER_STOP = "SPINNER_STOP";
export const SPINNER_ERROR_SET_MESSAGE = "SPINNER_ERROR_SET_MESSAGE";

export const spinnerStart = () => ({
  type: SPINNER_START,
});

export const spinnerStop = () => ({
  type: SPINNER_STOP,
});

export const spinnerSetError = (data) => ({
  type: SPINNER_ERROR_SET_MESSAGE,
  payload: { data },
});
