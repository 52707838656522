import React from "react";
import styled from "styled-components";
import Bertek from "../../assets/images/bertek-black-logo.png";
const Wraper = styled.div`
  position: relative;
  margin-top: 15px;
  width: 80%;
  min-width: 800px;
  height: 50px;
  display: flex;

  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
`;

const Logo = styled.div`
  height: 30px;
  width: 120px;
  margin-left: 40px;
`;
const Img = styled.img`
  height: 100%;
`;

const Text = styled.div`
  margin-top: 5px;
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%);
  color: rgb(46, 204, 113);
  font-size: 30px;
  font-weight: bold;
  width: 150px;
`;

const Navbar = () => (
  <>
    <Wraper>
      <Logo>
        <Img src={Bertek} />
      </Logo>
      <Text>CocoMilk</Text>
    </Wraper>
  </>
);

export default Navbar;
