import {
  STEPPER_MOVE_FORWARD,
  STEPPER_SET_STEP,
  STEPPER_SET_IS_EXPERIENCE_SCRAPED,
  STEPPER_SET_IS_EDUCATION_SCRAPED,
  STEPPER_SET_IS_LANGUAGES_SCRAPED,
  STEPPER_SET_IS_HOBBIES_SCRAPED,
  STEPPER_SET_IS_SKILLS_SCRAPED,
  STEPPER_SET_STEP_TYPE,
} from "../Actions/Actions";

const initialState = {
  Step: 1,
  isExperienceScraped: false,
  isEducationScraped: false,
  isLanguagesScraped: false,
  isSkillsScraped: false,
  isHobbiesScraped: false,
  stepType: "generate",
};

const stepper = (state = initialState, action) => {
  switch (action.type) {
    case STEPPER_MOVE_FORWARD: {
      return {
        ...state,
        Step: action.payload.data,
      };
    }
    case STEPPER_SET_STEP_TYPE: {
      return {
        ...state,
        stepType: action.payload.data,
      };
    }
    case STEPPER_SET_STEP: {
      return {
        ...state,
        Step: action.payload.data,
      };
    }
    case STEPPER_SET_IS_EXPERIENCE_SCRAPED: {
      return {
        ...state,
        isExperienceScraped: action.payload.data,
      };
    }
    case STEPPER_SET_IS_HOBBIES_SCRAPED: {
      return {
        ...state,
        isHobbiesScraped: action.payload.data,
      };
    }
    case STEPPER_SET_IS_EDUCATION_SCRAPED: {
      return {
        ...state,
        isEducationScraped: action.payload.data,
      };
    }
    case STEPPER_SET_IS_LANGUAGES_SCRAPED: {
      return {
        ...state,
        isLanguagesScraped: action.payload.data,
      };
    }
    case STEPPER_SET_IS_SKILLS_SCRAPED:
      return {
        ...state,
        isSkillsScraped: action.payload.data,
      };
    default:
      return state;
  }
};

export default stepper;
