import { CAREER_SET_BASIC_INFO } from "../Actions/Actions";

const initialState = {
  firstName: "",
  lastName: "",
  careerId: null,
  contactId: null,
};

const careerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CAREER_SET_BASIC_INFO: {
      return {
        ...state,
        firstName: action.payload.data.name.firstname,
        lastName: action.payload.data.name.lastname,
        careerId: action.payload.data.careerId,
        contactId: action.payload.data.contactId,
      };
    }
    default:
      return state;
  }
};

export default careerReducer;
