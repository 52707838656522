import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";

const checkBoxStyles = (theme) => ({
  root: {
    "&$checked": {
      color: "#2ECC71",
    },
  },
  checked: {},
});

const NewCheckbox = withStyles(checkBoxStyles)(Checkbox);

export default function CheckboxComponent({ handleChange, value, name }) {
  return (
    <FormControl component="fieldset">
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value={value}
          name={name}
          control={<NewCheckbox checked={value} />}
          label="Current Job ?"
          labelPlacement="end"
          onChange={handleChange}
        />
      </FormGroup>
    </FormControl>
  );
}
