import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  moveToNextStep,
  setIsSkillsScraped,
  spinnerStart,
  spinnerStop,
  setErrors,
  removeErrors,
  setSkills,
} from "../../../Redux/Actions/Actions";
import { useSnackbar } from "notistack";
import { setSkillsHelper } from "./helperFunctions";

//Formik
import { Formik, Form } from "formik";
import * as Yup from "yup";

//components
import Stepper from "../../ReusableComponents/Stepper/stepper";
import Button from "../../ReusableComponents/Button/button";
import PdfDocument from "../../ReusableComponents/PdfComponent/PdfDocument";
import StepHeader from "../../ReusableComponents/StepHeader/StepHeader";

import MultiSelect from "../../ReusableComponents/AutoCompleteMultiSelect/AutoCompleteMultiSelect";
import Input from "../../ReusableComponents/TextInput/textInput";
import TextFieldChip from "../../ReusableComponents/TextFieldChip/TextFieldChip";
import {
  InputWraper,
  FormWraper,
  Spliter,
  ButtonWraper,
  Cv,
  FormFields,
  StyleForm,
  Wraper,
  Dots,
} from "./StyledComponents";
//CareerService
import CareerService from "../../../Services/CareerService";

const skillsSchema = Yup.object().shape({
  businessLine: Yup.string(),
  crossDisciplinarySkills: Yup.string(),
  db: Yup.string(),
  frameworks: Yup.string(),
  languages: Yup.string(),
  methodology: Yup.string(),
  softSkills: Yup.string(),
  tools: Yup.string(),
  businessSector: Yup.string().required(),
});

const Skills = ({
  moveToNextStep,
  file,
  isSkillsScraped,
  setIsSkillsScraped,
  careerId,
  careerName,
  careerLastName,
  cvLanguage,
  cvTimeStamp,
  spinnerStart,
  spinnerStop,
  fileFull,
  setErrors,
  removeErrors,
  skills,
  setSkills,
  errorsState,
  stepType,
  outputLanguage,
}) => {
  const [fullWidthForm, setFullWidthForm] = useState(false);
  const [initialValues, setInitialValues] = useState({
    ...skills,
  });
  const [localErrors, setLocalErrors] = useState([]);
  const [showErrors, setShowErrors] = useState(true);
  const [businessSectorOption, setBusinessSectorOption] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  //
  // Business Sector Options
  //
  useEffect(() => {
    async function getBusinessSectors() {
      const {
        result,
        responseCode,
      } = await CareerService.getBusinessSectorOptions(outputLanguage);
      if (responseCode >= 400) {
        enqueueSnackbar("Get Business Sector Options - error", {
          variant: "error",
        });
      } else {
        setBusinessSectorOption(result);
      }
    }
    getBusinessSectors();
  }, [enqueueSnackbar, setBusinessSectorOption, outputLanguage]);

  //get data update step
  useEffect(() => {
    async function getData() {
      if (stepType === "updateStep" && isSkillsScraped === false) {
        spinnerStart();
        //skills
        const skills = await CareerService.getSkills(careerId, outputLanguage);

        if (skills.responseCode >= 400) {
          enqueueSnackbar("Get Skills - error", {
            variant: "error",
          });
        } else {
          if (skills.result) {
            const skill = skills.result.value[0];
            const init = setSkillsHelper(skill);
            setInitialValues(init);
          }
        }
        spinnerStop();
      }
    }
    getData();
  }, [
    careerId,
    enqueueSnackbar,
    stepType,
    spinnerStart,
    spinnerStop,
    isSkillsScraped,
    outputLanguage,
  ]);

  //set initial skills
  useEffect(() => {
    setLocalErrors(errorsState);
    setInitialValues({ ...skills });
  }, [skills, setInitialValues, setLocalErrors, errorsState]);

  //errors
  useEffect(() => {
    if (localErrors) {
      for (let i = 0; i < localErrors.length; i++) {
        enqueueSnackbar(localErrors[i], {
          variant: "error",
        });
      }
    }
    setShowErrors(false);
  }, [localErrors, enqueueSnackbar, showErrors]);

  //scrap skills
  useEffect(() => {
    const setData = async () => {
      spinnerStart();

      if (!isSkillsScraped) {
        //scrap
        let fileType = "";
        if (fileFull.type === "application/pdf") {
          fileType = ".pdf";
        } else {
          fileType = ".docx";
        }
        const code = cvTimeStamp;
        const language = cvLanguage.toLowerCase();

        const data = new FormData();

        data.append("type", language);
        data.append("extension", fileType);
        data.append("code", code);

        const {result,responseCode} = await CareerService.scrapSkills(data, code);
        
        if (responseCode >= 400) {
          enqueueSnackbar("Scrap Skills - Error", {
            variant: "error",
          });
        } else {
          //Here create object

          const skills = {
            businessLine: result.functional.businessLine,
            crossDisciplinarySkills: result.functional.crossSkill.join(","),
            softSkills: result.functional.softSkill.join(","),
            businessSector: result.functional.businessSectors?result.functional.businessSectors.map((item) => {
              return { title: item };
            }):"",
            db: result.technical.db.join(","),
            frameworks: result.technical.frameworks.join(","),
            languages: result.technical.languages.join(","),
            methodology: result.technical.methodology.join(","),
            tools: result.technical.tools.join(",")           
          };

          //Save skills in redux store
          setSkills(skills);
        }
        setIsSkillsScraped(true);
      }
      spinnerStop();
    };

    if (stepType === "importStep") {
      setData();
    }
  }, [
    stepType,
    setSkills,
    enqueueSnackbar,
    setIsSkillsScraped,
    file,
    errorsState,
    careerId,
    careerLastName,
    careerName,
    cvLanguage,
    cvTimeStamp,
    isSkillsScraped,
    spinnerStart,
    spinnerStop,
    fileFull,
  ]);

  useEffect(() => {
    document.title = "Functionnal & Technical Skills";
  }, []);

  const dotsClick = () => {
    setFullWidthForm(!fullWidthForm);
  };

  const stepperClicked = (values) => {
    setSkills({ ...values });
    setIsSkillsScraped(true);
  };

  return (
    <Wraper>
      <StyleForm fullWidthForm={fullWidthForm}>
        <FormFields>
          <FormWraper>
            <Formik
              validateOnChange={true}
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={skillsSchema}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                spinnerStart();
                removeErrors();

                let businessSectors = "";
                for (let i = 0; i < values.businessSector.length; i++) {
                  if (businessSectors.length === 0) {
                    businessSectors = values.businessSector[i].title;
                  } else {
                    businessSectors =
                      businessSectors + "," + values.businessSector[i].title;
                  }
                }
                
                const model = {
                  BusinessSector: businessSectors.split(','),
                  BusinessLine: values.businessLine.split('\n'),
                  SoftSkills: values.softSkills.split(','),
                  CrossDisciplinarySkills: values.crossDisciplinarySkills.split(','),
                  ProgramingLanguages: values.languages.split(','),
                  Frameworks: values.frameworks.split(','),
                  DB: values.db.split(','),
                  Tools: values.tools.split(','),
                  Methodology: values.methodology.split(','),
                  type: "SKILLS",
                  careerId,
                };

                //set skills in redux
                setSkills({ ...values });
                const { responseCode } = await CareerService.sendSkills(model);
                if (responseCode >= 400) {
                  const errorArray = [];
                  errorArray.push("Send Skills - Error");
                  setErrors(errorArray);
                }
                setIsSkillsScraped(true);
                spinnerStop();
                setSubmitting(false);
                moveToNextStep(6);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => (
                <>
                  <Stepper
                    StepNumber={3}
                    onClick={() => stepperClicked(values)}
                    isUpdate={stepType === "updateStep"}
                  />
                  <StepHeader>
                    Here are the candidate's functional and technical skills
                    found on the CV. You can complete it if needed.
                  </StepHeader>
                  <Form autoComplete={"off"}>
                    <Spliter>Functional Skills</Spliter>
                    <InputWraper>
                    <Input
                        handleBlur={handleBlur}
                        name={"businessLine"}
                        label={"Business Line"}
                        variant={"outlined"}
                        value={values.businessLine ? values.businessLine : ""}
                        valueChange={handleChange}
                      />                    
                    </InputWraper>
                    <InputWraper>
                      <MultiSelect
                        label={"Business Sector"}
                        handleBlur={handleBlur}
                        error={errors.businessSector && touched.businessSector}
                        variant={"outlined"}
                        value={values.businessSector}
                        valueChange={handleChange}
                        name={"businessSector"}
                        setFieldValue={setFieldValue}
                        businessSectors={businessSectorOption}
                      />
                    </InputWraper>
                    <InputWraper>
                      <TextFieldChip
                        name={"softSkills"}
                        label={"Soft Skills"}
                        variant={"outlined"}
                        value={values.softSkills}
                        valueChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    </InputWraper>
                    <InputWraper>
                      <TextFieldChip
                        name={"crossDisciplinarySkills"}
                        label={"Cross-disciplinary Skills"}
                        variant={"outlined"}
                        value={values.crossDisciplinarySkills}
                        valueChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    </InputWraper>
                    <Spliter>Technical Skills</Spliter>
                    <InputWraper>
                      <TextFieldChip
                        name={"languages"}
                        label={"Languages"}
                        variant={"outlined"}
                        value={values.languages}
                        valueChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    </InputWraper>
                    <InputWraper>
                      <TextFieldChip
                        name={"frameworks"}
                        label={"Frameworks"}
                        variant={"outlined"}
                        value={values.frameworks}
                        valueChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    </InputWraper>
                    <InputWraper>
                      <TextFieldChip
                        name={"db"}
                        label={"DB"}
                        variant={"outlined"}
                        value={values.db}
                        valueChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    </InputWraper>
                    <InputWraper>
                      <TextFieldChip
                        name={"tools"}
                        label={"Tools"}
                        variant={"outlined"}
                        value={values.tools}
                        valueChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    </InputWraper>
                    <InputWraper>
                      <TextFieldChip
                        name={"methodology"}
                        label={"Methodology"}
                        variant={"outlined"}
                        value={values.methodology}
                        valueChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    </InputWraper>

                    <ButtonWraper>
                      <Button
                        height={30}
                        width={120}
                        text={"Previous"}
                        submit={true}
                        fontSize={16}
                        marginLeft={30}
                        marginBottom={20}
                        handleClick={(event) => {
                          setSkills({ ...values });
                          setIsSkillsScraped(true);
                          event.preventDefault();
                          moveToNextStep(4);
                        }}
                      />
                      <Button
                        height={30}
                        width={120}
                        text={"Next"}
                        submit={true}
                        left
                        fontSize={16}
                        marginRight={30}
                        marginBottom={20}
                      />
                    </ButtonWraper>
                  </Form>
                </>
              )}
            </Formik>
          </FormWraper>
        </FormFields>

        {stepType === "importStep" && <Dots onClick={dotsClick} />}
      </StyleForm>

      {!fullWidthForm && stepType === "importStep" && (
        <Cv>
          <PdfDocument file={file} />
        </Cv>
      )}
    </Wraper>
  );
};

const mapStateToProps = (state) => {
  return {
    stepType: state.Stepper.stepType,
    errorsState: state.Errors.errors,
    file: state.CvGenerator.cvFile,
    fileFull: state.CvGenerator.cvFilePdf,
    isSkillsScraped: state.Stepper.isSkillsScraped,
    careerId: state.Career.careerId,
    careerName: state.Career.firstName,
    careerLastName: state.Career.lastName,
    cvLanguage: state.CvGenerator.cvLanguage,
    cvTimeStamp: state.CvGenerator.cvTimeStamp,
    skills: state.Skills,
    outputLanguage: state.CvGenerator.cvOutputLanguage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setErrors: (data) => dispatch(setErrors(data)),
    removeErrors: () => dispatch(removeErrors()),
    moveToNextStep: (data) => dispatch(moveToNextStep(data)),
    setIsSkillsScraped: (data) => dispatch(setIsSkillsScraped(data)),
    spinnerStart: () => dispatch(spinnerStart()),
    spinnerStop: () => dispatch(spinnerStop()),
    setSkills: (data) => dispatch(setSkills(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Skills);
