// Education
export const EDUCATION_SET_EDU_ARRAY = "EDUCATION_SET_EDU_ARRAY";
export const EDUCATION_REMOVE_EDU_FROM_ARRAY =
  "EDUCATION_REMOVE_EDU_FROM_ARRAY";
export const EDUCATION_ADD_EDU_TO_ARRAY = "EDUCATION_ADD_EDU_TO_ARRAY";

export const setEducationArray = (data) => ({
  type: EDUCATION_SET_EDU_ARRAY,
  payload: { data },
});

export const removeEducationFromArray = (data) => ({
  type: EDUCATION_REMOVE_EDU_FROM_ARRAY,
  payload: { data },
});

export const addEducation = () => ({
  type: EDUCATION_ADD_EDU_TO_ARRAY,
});
