export const setSkillsHelper = (skill,separator) => {
  let init = {};
  let businessSector = "";
  if (skill.btk_businesssectors) {
    businessSector = skill.btk_businesssectors.split(', ').map((item) => {
      return { title: item };
    });
  }

  init.crossDisciplinarySkills =
    skill.btk_crossdisciplinaryskills === null
      ? ""
      : skill.btk_crossdisciplinaryskills.split(', ').join(',').split(',').join(',');
  init.businessLine =
    skill.btk_businessline === null ? "" : skill.btk_businessline.split(', ').join(',').split(',').join(',');
  init.businessSector = businessSector;
  init.softSkills = skill.btk_softskills === null ? "" : skill.btk_softskills.split(', ').join(',').split(',').join(',');

  init.languages =
    skill.btk_programinglanguages === null ? "" : skill.btk_programinglanguages.split(', ').join(',').split(',').join(',');
  init.frameworks = skill.btk_frameworks === null ? "" : skill.btk_frameworks.split(', ').join(',').split(',').join(',');
  init.db = skill.btk_databases === null ? "" : skill.btk_databases.split(', ').join(',').split(',').join(',');
  init.tools = skill.btk_tools === null ? "" : skill.btk_tools.split(', ').join(',').split(',').join(',');
  init.methodology =
    skill.btk_methodologies === null ? "" : skill.btk_methodologies.split(', ').join(',').split(',').join(',');

  return init;
};
