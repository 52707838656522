import ChipInput from "material-ui-chip-input";
import Chip from '@material-ui/core/Chip';
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from 'react'

const useStyles = makeStyles({
  label: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
});

export default function TextFieldChip({ value, setFieldValue, name, label }) {
  const classes = useStyles();

  const initial = () => {
    let array = [];
    if (value) {
      if (Array.isArray(value)) {
        array = value;
      } else {
        array = value.split(", ").join(',').split(',');
      }
    }
    return array;
  };

  const [chips, setChips] = useState([]);

  const handleDragStart = (event, chip) => {
    event.dataTransfer.setData('text/plain', chip);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const chip = event.dataTransfer.getData('text/plain');

    let newArr = [];
    if (Array.isArray(value)) {
      newArr = chips;
    } else {
      newArr = value.split(",");
    }
    const index = newArr.indexOf(chip);

    // Find the chip element under the drop target
    const chipElement = event.target.closest('.MuiChip-label');

    if(chipElement)
    {
      const value = chipElement.innerHTML;

      if (value) {
        const droppedChipIndex = newArr.indexOf(value);
        const newChips = [...newArr];
        const [removedChip] = newChips.splice(index, 1);
        newChips.splice(droppedChipIndex, 0, removedChip);
        
        if (Array.isArray(newChips)) {
          setFieldValue(name, newChips.join(","));
          setChips(newChips);
        } else {
          setFieldValue(name, newChips.join(","));
          setChips(newChips);
        }
      }
  }
  };
  

  return (
    <div
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{ border: '0px solid black', padding: '0px', minHeight: '50px' }}
    >
    <ChipInput
      className={classes.label}
      placeholder={label}
      alwaysShowPlaceholder={true}
      fullWidth={true}
      onAdd={(value) => {
        const initialData = initial();

        if (!value.includes(",") && initialData.length === 0) {
          setFieldValue(name, value);
        } else if (initialData.length !== 0) {
          setFieldValue(name, initial() + "," + value.split(", ").join(',').split(',').join(','));
        } else {
          setFieldValue(name, value.split(", ").join(',').split(',').join(','));
        }
        setChips(value);
      }}
      allowDuplicates={false}
      variant={"outlined"}
      value={value ? initial() : []}
      onDelete={(chip, index) => {
        setFieldValue(name, []);
        if (value) {
          let array = [];
          if (Array.isArray(value)) {
            array = value;
          } else {
            array = value.split(",");
          }

          const newArr = array.filter((c) => c !== chip);
          if (Array.isArray(newArr)) {
            setFieldValue(name, newArr.join(","));
          } else {
            setFieldValue(name, newArr);
          }
        }
      }}
      chipRenderer={({ value, handleDelete, className }) => (
        <Chip
          key={value}
          label={value}
          className={className}
          onDelete={handleDelete}
          draggable
          onDragStart={(event) => handleDragStart(event, value)}
        />
      )}
    />
    </div>
  );
}
