import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";

import "react-datepicker/dist/react-datepicker.css";
import "./styleCustom.css";

const CustomDatePicker = ({
  date,
  setDate,
  text,
  error,
  big,
  name,
  handleBlur,
}) => {
  const [className, setClassName] = useState("");
  useEffect(() => {
    let className = "";
    setClassName("small");
    if (error) {
      className = "small-error";
    }
    if (big) {
      className = "height";
    }
    if (big && error) {
      className = "height-error";
    }
    setClassName(className);
  }, [error, big]);
  const oldDate = new Date();
  return (
    <DatePicker
      name={name}
      onBlur={handleBlur}
      className={className}
      utcOffset={0}
      placeholderText={text}
      maxDate={new Date(oldDate.setMonth(oldDate.getMonth() + 1))}
      dateFormat="MM/yyyy"
      selected={date}
      showMonthYearPicker
      closeOnScroll={true}
      onChange={(newdate) => {
        if (newdate !== null) {
          setDate(name, newdate);
        } else {
          setDate(name, null);
        }
      }}
    />
  );
};

const BigDatePicker = styled(CustomDatePicker)`
  height: 56px "important";
`;

export default BigDatePicker;
