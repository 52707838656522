//Experience

export const EXPERIENCE_ADD_NEW_EXPERIENCE = "EXPERIENCE_ADD_NEW_EXPERIENCE";
export const EXPERIENCE_REMOVE_EXPERIENCE = "EXPERIENCE_REMOVE_EXPERIENCE";
export const EXPERIENCE_UPDATE_EXPERIENCE = "EXPERIENCE_UPDATE_EXPERIENCE";
export const EXPERIENCE_TITLE_OF_CV_CHANGE = "EXPERIENCE_TITLE_OF_CV_CHANGE";
export const EXPERIENCE_YEARS_OF_EXPERIENCE_CHANGE = "EXPERIENCE_YEARS_OF_EXPERIENCE_CHANGE";

export const addExperience = (data) => ({
  type: EXPERIENCE_ADD_NEW_EXPERIENCE,
  payload: { data },
});

export const updateExperience = (data) => ({
  type: EXPERIENCE_UPDATE_EXPERIENCE,
  payload: { data },
});

export const removeExperience = (data) => ({
  type: EXPERIENCE_REMOVE_EXPERIENCE,
  payload: { data },
});

export const titleOfCvChange = (data) => ({
  type: EXPERIENCE_TITLE_OF_CV_CHANGE,
  payload: { data },
});

export const yearsOfExperienceChange = (data) => ({
  type: EXPERIENCE_YEARS_OF_EXPERIENCE_CHANGE,
  payload: { data },
});
