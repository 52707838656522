import React from "react";
import { connect } from "react-redux";

//components
import Navbar from "../Navbar/navBar";

import Experience from "../CvGenerator/Experiences/experiences";
import Education from "../CvGenerator/Education/education";
import Languages from "../CvGenerator/Languages/languages";
import Skills from "../CvGenerator/Skills/skills";
import Hobbies from "../CvGenerator/Hobbies/hobbies";
import GenerateCv from "../CvGenerator/GenerateCv/GenerateCv";
import UpdateInitialPage from "../CvGenerator/UpdateInitialPage/UpdateInitialPage"
import CvDone from "../CvBertekDone/CvDone";
import Spinner from "../ReusableComponents/Spinner/spinner";

function SubContent({ Step, isLoading }) {
  return (
    <div>
      {isLoading && <Spinner />}
      {Step !== 1 && Step !== 0 && <Navbar />}
      {Step === 2 && <Experience />}
      {Step === 3 && <Education />}
      {Step === 4 && <Languages />}
      {Step === 5 && <Skills />}
      {Step === 6 && <Hobbies />}
      {Step === 7 && <GenerateCv />}
      {Step === 9 && <CvDone />}
      {Step === 10 && <UpdateInitialPage />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    Step: state.Stepper.Step,
    isLoading: state.Spinner.isSpinnerOn,
  };
};

export default connect(mapStateToProps, null)(SubContent);
