export const STEPPER_MOVE_FORWARD = "STEPPER_MOVE_FORWARD";
export const STEPPER_SET_STEP = "STEPPER_SET_STEP";
export const STEPPER_SET_IS_EXPERIENCE_SCRAPED =
  "STEPPER_SET_IS_EXPERIENCE_SCRAPED";
export const STEPPER_SET_IS_EDUCATION_SCRAPED =
  "STEPPER_SET_IS_EDUCATION_SCRAPED";
export const STEPPER_SET_IS_LANGUAGES_SCRAPED =
  "STEPPER_SET_IS_LANGUAGES_SCRAPED";
export const STEPPER_SET_IS_SKILLS_SCRAPED = "STEPPER_SET_IS_SKILLS_SCRAPED";
export const STEPPER_SET_IS_HOBBIES_SCRAPED = "STEPPER_SET_IS_HOBBIES_SCRAPED";
export const STEPPER_SET_STEP_TYPE = "STEPPER_SET_STEP_TYPE";

export const moveToNextStep = (data) => ({
  type: STEPPER_MOVE_FORWARD,
  payload: { data },
});

export const setStepType = (data) => ({
  type: STEPPER_SET_STEP_TYPE,
  payload: { data },
});

export const setStep = (data) => ({
  type: STEPPER_SET_STEP,
  payload: { data },
});

export const setIsExperienceScraped = (data) => ({
  type: STEPPER_SET_IS_EXPERIENCE_SCRAPED,
  payload: { data },
});
export const setIsEducationScraped = (data) => ({
  type: STEPPER_SET_IS_EDUCATION_SCRAPED,
  payload: { data },
});
export const setIsLanguagesScraped = (data) => ({
  type: STEPPER_SET_IS_LANGUAGES_SCRAPED,
  payload: { data },
});
export const setIsSkillsScraped = (data) => ({
  type: STEPPER_SET_IS_SKILLS_SCRAPED,
  payload: { data },
});
export const setIsHobbiesScraped = (data) => ({
  type: STEPPER_SET_IS_HOBBIES_SCRAPED,
  payload: { data },
});
