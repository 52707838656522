import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
    "& .MuiOutlinedInput-input": {
      color: "black",
    },
    "& .MuiInputLabel-root": {
      color: "black",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "black",
    },
    "&:hover .MuiInputLabel-root": {
      color: "black",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "black",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "black",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
  },
}));

const DropDownList = ({
  menuItems,
  labelName,
  value,
  changeEvent,
  isDefault,
  name,
  small,
}) => {
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      className={classes.root}
      size={small && "small"}
    >
      <InputLabel id="demo-simple-select-outlined-label">
        {labelName}
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="select-outlined"
        value={value}
        onChange={changeEvent}
        label={labelName}
        name={name}
      >
        {isDefault && <MenuItem value={"None"}>None</MenuItem>}
        {menuItems.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

DropDownList.defaultProps = {
  menuItems: [
    { value: "A1" },
    { value: "A2" },
    { value: "B1" },
    { value: "B2" },
    { value: "C1" },
    { value: "C2" },
  ],
};

DropDownList.propTypes = {
  menuItems: PropTypes.array,
  labelName: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  changeEvent: PropTypes.func.isRequired,
};

export default DropDownList;
