import React, { useState } from "react";
import { connect } from "react-redux";

//Actions
import {
  setStep,
  spinnerStart,
  spinnerStop,
  setOutputLanguage,
} from "../../../Redux/Actions/Actions";

//components
import RadioButton from "../../ReusableComponents/RadioButton/radioButton";
import Button from "../../ReusableComponents/Button/button";
import {
  RadioWrapper,
  Splitter,
  RadioButtons,
  Text,
  ButtonWraper,
  SplitterText,
  Form,
  Wraper,
} from "./StyledComponents";

function UpdateInitialPage({
  setStep,
  spinnerStart,
  spinnerStop,
  setOutputLanguage,
}) {
  const [cvOutputLanguage, setCvOutputLanguage] = useState(null);
  const [radioOutputLanguageError, setRadioOutputLanguageError] = useState(
    false
  );

  const radioOutputLanguageChange = (e) => {
    setCvOutputLanguage(e.target.value);
    setRadioOutputLanguageError(false);
  };

  const handleNextClick = (e) => {
    e.preventDefault();

    if (cvOutputLanguage === null) {
      setRadioOutputLanguageError(true);
    } else {
      spinnerStart();
      setOutputLanguage(cvOutputLanguage);
      spinnerStop();
      setStep(2);
    }
  };

  return (
    <Wraper>
      <Form>
        <SplitterText style={{ marginTop: "140px" }}>
          Tell us the language of the Bertek CV you are going to create
        </SplitterText>
        <Splitter />
        <RadioButtons>
          <RadioWrapper>
            <RadioButton
              handleChange={radioOutputLanguageChange}
              value={"English"}
              checked={cvOutputLanguage === "English" ? true : false}
            />{" "}
            <Text error={radioOutputLanguageError}>English</Text>
          </RadioWrapper>
          <RadioWrapper>
            <RadioButton
              handleChange={radioOutputLanguageChange}
              value={"French"}
              checked={cvOutputLanguage === "French" ? true : false}
            />{" "}
            <Text error={radioOutputLanguageError}>French</Text>
          </RadioWrapper>
          <RadioWrapper>
            <RadioButton
              handleChange={radioOutputLanguageChange}
              value={"Polish"}
              checked={cvOutputLanguage === "Polish" ? true : false}
            />{" "}
            <Text error={radioOutputLanguageError}>Polish </Text>
          </RadioWrapper>
        </RadioButtons>
        <ButtonWraper>
          <Button
            height={30}
            width={220}
            text={"Next"}
            handleClick={handleNextClick}
            left
            fontSize={16}
          />
        </ButtonWraper>
      </Form>
    </Wraper>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setStep: (step) => dispatch(setStep(step)),
    spinnerStart: () => dispatch(spinnerStart()),
    spinnerStop: () => dispatch(spinnerStop()),
    setOutputLanguage: (data) => dispatch(setOutputLanguage(data)),
  };
};

export default connect(null, mapDispatchToProps)(UpdateInitialPage);
