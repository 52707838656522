import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";

const filter = createFilterOptions();

const CssTextField = withStyles({
  root: {
    width: "100%",
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
})(TextField);

export default function FreeSoloCreateOption({
  values,
  label,
  setFieldValue,
  name,
  data,
}) {
  const [value, setValue] = useState(data);
  useEffect(() => {
    setValue(data);
  }, [setValue, data]);

  const clearValues = values.map((c) => c.btk_name);

  return (
    <Autocomplete
      style={{ width: "100%" }}
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setValue({
            newValue,
          });
          setFieldValue(name, newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue(newValue.inputValue.toString());
        } else {
          setValue(newValue);
          setFieldValue(name, newValue ? newValue : "");
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={clearValues}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (option.newValue && option.newValue.length !== 0) {
          return option.newValue.toString();
        }

        if (typeof option.newValue === "string") {
          return option;
        }

        return option;
      }}
      renderOption={(option) => option}
      freeSolo
      renderInput={(params) => (
        <CssTextField {...params} label={label} variant="outlined" />
      )}
    />
  );
}
