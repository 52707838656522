import React, { useState } from "react";

import { connect } from "react-redux";
import {
  cvChange,
  linkedInChange,
  languageChange,
  moveToNextStep,
  spinnerStop,
  spinnerStart,
  cvPdfChange,
  setScrapedData,
  setOutputLanguage,
  setErrors,
} from "../../../Redux/Actions/Actions";
import { isLinkedinProfileUrl } from "../../../RegexHelper/linkedInHelper";

//Service
import CvService from "../../../Services/CvService";

//components
import FileInput from "../../ReusableComponents/FileInput/fileInput";
//import TextInput from "../../ReusableComponents/TextInput/textInput";
import Button from "../../ReusableComponents/Button/button";
import RadioButton from "../../ReusableComponents/RadioButton/radioButton";
import {
  RadioWrapper,
  RadioButtons,
  Text,
  SplitterText,
  Splitter,
  FileInputWraper,
  UploadBox,
  ErrorMessage,
  //DividerBox,
  DataBox,
  ButtonWraper,
  BoxText,
  HeaderText,
  TrashIcon,
  Wraper,
} from "./StyledComponents";

const UploadPage = ({
  cvChange,
  cvFile,
  linkedIn,
  linkedInChange,
  cvFileName,
  // cvLanguage,
  languageEvent,
  moveToNextStep,
  Step,
  spinnerStart,
  spinnerStop,
  cvPdfChange,
  firstName,
  lastName,
  careerId,
  cvFilePdf,
  businessid,
  setOutputLanguage,
  cvOutputLanguage,
  setErrors,
}) => {
  const [error, setError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [fileErrorMessage] = useState(
    "Upload PDF or .docx Word files smaller than 3Mb"
  );
  const [radioError, setRadioError] = useState(false);
  const [radioOutputLanguageError, setRadioOutputLanguage] = useState(false);

  //Move to next step
  const handleNextClick = async () => {
    const errorArray = [];
    let isCvExist = false;
    let isMatch = false;
    let isRadioChecked = false;
    let isLanguageRadioChecked = false;

    if (linkedIn.length > 0) {
      isMatch = isLinkedinProfileUrl(linkedIn);
    }
    if (cvFile === null) {
      isCvExist = false;
      if (linkedIn.length < 1) setFileError(true);
    } else {
      if (cvFile.length > 0) {
        isCvExist = true;
        isMatch = true;
      }
    }

    if (isMatch) {
      setError(false);
    } else {
      if (!isCvExist) setError(true);
    }
    // if (cvLanguage.length > 0) {
      isRadioChecked = true;
      setRadioError(false);
    // } else {
    //   setRadioError(true);
    // }
    if (cvOutputLanguage.length > 0) {
      isLanguageRadioChecked = true;
      setRadioOutputLanguage(false);
    } else {
      setRadioOutputLanguage(true);
    }
    if (isRadioChecked && isLanguageRadioChecked && (isMatch || isCvExist)) {
      //If cv exist - send, else scrap linkedin
      spinnerStart();
      if (isCvExist) {
        //send orginal CV to crm

        if (cvFilePdf.type !== "application/pdf") {
          //Convert file to

          const filename = `${Date.now() / 1000}.docx`;
          const data = new FormData();
          data.append("file", cvFilePdf);
          data.append("filename", filename);
          data.append("businessid", businessid);

          const { responseCode } = await CvService.sendToSharepoint(data);
          if (responseCode >= 400) {
            // error
            errorArray.push("Convert Docx in Pdf - Send - Error");
          }

          const { file, code } = await CvService.getFile(filename, businessid);
          if (code >= 400) {
            //error
            errorArray.push("Convert Docx in Pdf - Get - Error");
          } else {
            cvChange({ source: file, name: cvFilePdf.filename });
          }
        }
        //Send file to store in Note
        const file = {
          documentbody: cvFile,
          filetype: cvFilePdf.type,
          careerId,
          filename: firstName + lastName,
        };
        const { response, responseCode } = await CvService.sendNoteToCrm(file);

        if (responseCode >= 400) {
          //error
          errorArray.push("Send Note to CRM - Error");
        } else {
          setScrapedData(response);
        }

        setErrors(errorArray);
      }
      setError(false);
      setRadioError(false);
      setRadioOutputLanguage(false);
      moveToNextStep(Step + 1);

    }
  };

  const removeFile = () => {
    cvPdfChange(null);
    cvChange({ source: null, name: "" });
  };

  //File Change
  const fileChange = async (e) => {
    let file = e.target.files[0];

    if ( !file ||
      (file.type !== "application/pdf" &&
      file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") ||
      file.size > 3000000
    ) {
      file = null;
      cvPdfChange(null);
      cvChange("");
      setFileError(true);
    } else {
      setFileError(false);
      cvPdfChange(file);
      let fileToLoad = file;
      const fileName = fileToLoad.name;
      // FileReader function for read the file.
      let fileReader = new FileReader();
      // Onload of file read the file content
      fileReader.onload = function (fileLoadedEvent) {
        file = fileLoadedEvent.target.result;

        file = file.split(",")[1];

        const fileBase64 = {
          name: fileName,
          source: file,
        };
        cvChange(fileBase64);
      };
      // Convert data to base64
      fileReader.readAsDataURL(fileToLoad);
    }
  };
  /*const profileChange = (e) => {
    const profile = e.target.value;

    linkedInChange(profile);
  }; */

  // const radioChange = (e) => {
  //   languageEvent(e.target.value);
  // };

  const radioOutputLanguageChange = (e) => {
    setOutputLanguage(e.target.value);
  };
  return (
    <Wraper>
      <HeaderText>
        {" "}
        You received a new resume ? Cocomilk helps you transforming the original
        candidate's CV to the Bertek CV
      </HeaderText>

      <DataBox>
        <UploadBox style={{ height: "20px", marginTop: "100px" }}>
          <BoxText>Upload the candidate's CV here:</BoxText>
          {cvFile && <TrashIcon onClick={removeFile} />}

          <FileInputWraper>
            <FileInput
              handleChange={fileChange}
              value={cvFileName ? cvFileName : ""}
              error={fileError}
              disabled={linkedIn.length !== 0 ? true : false}
            />
            {fileError && <ErrorMessage>{fileErrorMessage}</ErrorMessage>}
          </FileInputWraper>
        </UploadBox>
{/*         <DividerBox>
          <p>Or</p>
        </DividerBox>
        <UploadBox style={{ marginTop: "20px" }}>
          <BoxText>Enter your candidate LinkedIn profile:</BoxText>
          <FileInputWraper>
            <TextInput
              valueChange={profileChange}
              value={linkedIn}
              label={"LinkedIn"}
              black
              error={error}
              helperText={error ? "This is not LinkedIn profile page" : " "}
              disabled={cvFile !== null ? true : false}
            />
          </FileInputWraper>
        </UploadBox> */}
        {/* <SplitterText>
          Tell us the language of the uploaded CV 
        </SplitterText>
        <Splitter />
        <RadioButtons>
          <RadioWrapper>
            <RadioButton
              error={radioError}
              handleChange={radioChange}
              value={"English"}
              checked={cvLanguage === "English" ? true : false}
            />{" "}
            <Text error={radioError}>English</Text>
          </RadioWrapper>
          <RadioWrapper>
            <RadioButton
              error={radioError}
              handleChange={radioChange}
              value={"French"}
              checked={cvLanguage === "French" ? true : false}
            />{" "}
            <Text error={radioError}>French</Text>
          </RadioWrapper>
          <RadioWrapper>
            <RadioButton
              error={radioError}
              handleChange={radioChange}
              value={"Polish"}
              checked={cvLanguage === "Polish" ? true : false}
            />{" "}
            <Text error={radioError}>Polish </Text>
          </RadioWrapper>
        </RadioButtons> */}
        <SplitterText style={{ marginTop: "120px" }}>
          Tell us the language of the Bertek CV you are going to create
        </SplitterText>
        <Splitter />
        <RadioButtons>
          <RadioWrapper>
            <RadioButton
              error={radioError}
              handleChange={radioOutputLanguageChange}
              value={"English"}
              checked={cvOutputLanguage === "English" ? true : false}
            />{" "}
            <Text error={radioOutputLanguageError}>English</Text>
          </RadioWrapper>
          <RadioWrapper>
            <RadioButton
              error={radioError}
              handleChange={radioOutputLanguageChange}
              value={"French"}
              checked={cvOutputLanguage === "French" ? true : false}
            />{" "}
            <Text error={radioOutputLanguageError}>French</Text>
          </RadioWrapper>
          <RadioWrapper>
            <RadioButton
              error={radioError}
              handleChange={radioOutputLanguageChange}
              value={"Polish"}
              checked={cvOutputLanguage === "Polish" ? true : false}
            />{" "}
            <Text error={radioOutputLanguageError}>Polish </Text>
          </RadioWrapper>
        </RadioButtons>
      </DataBox>
      <ButtonWraper>
        <Button
          height={30}
          width={120}
          text={"Send"}
          handleClick={handleNextClick}
          left
          fontSize={16}
        />
      </ButtonWraper>
    </Wraper>
  );
};

const mapStateToProps = (state) => {
  return {
    cvFile: state.CvGenerator.cvFile,
    cvFilePdf: state.CvGenerator.cvFilePdf,
    cvFileName: state.CvGenerator.cvFileName,
    linkedIn: state.CvGenerator.linkedIn,
    cvLanguage: state.CvGenerator.cvLanguage,
    cvOutputLanguage: state.CvGenerator.cvOutputLanguage,
    Step: state.Stepper.Step,
    firstName: state.Career.firstName,
    lastName: state.Career.lastName,
    careerId: state.Career.careerId,
    businessid: state.Identity.businessUnitId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setErrors: (data) => dispatch(setErrors(data)),
    cvChange: (file) => dispatch(cvChange(file)),
    linkedInChange: (profile) => dispatch(linkedInChange(profile)),
    languageEvent: (data) => dispatch(languageChange(data)),
    moveToNextStep: (step) => dispatch(moveToNextStep(step)),
    spinnerStart: () => dispatch(spinnerStart()),
    spinnerStop: () => dispatch(spinnerStop()),
    cvPdfChange: (data) => dispatch(cvPdfChange(data)),
    setScrapedData: (data) => dispatch(setScrapedData(data)),
    setOutputLanguage: (data) => dispatch(setOutputLanguage(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadPage);
