import styled from "styled-components";

export const Wraper = styled.div`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 80vh;
  margin-top: 50px;
`;

export const Form = styled.div`
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;

export const SplitterText = styled.div`
  margin-top: 40px;
  font-size: 18px;
  width: 80%;
  text-align: left;
  margin-top: 70px;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-right: auto;
`;

export const ButtonWraper = styled.div`
  margin-top: 30px;
  height: 5%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
`;

export const Text = styled.div`
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  color: ${({ error }) => (error ? "red" : "black")};
`;

export const RadioButtons = styled.div`
  display: flex;
  width: 60%;
  align-items: flex-start;
`;

export const Splitter = styled.div`
  height: 2px;
  border-bottom: 1px solid black;
  width: 100%;
`;
export const RadioWrapper = styled.div`
  width: 33%;
  display: flex;
  align-items: flex-start;
`;
