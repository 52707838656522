import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import {
  moveToNextStep,
  setEducationArray,
  setIsEducationScraped,
  setTimeStamp,
  spinnerStop,
  spinnerStart,
  setErrors,
  removeErrors,
} from "../../../Redux/Actions/Actions";

//components
import Stepper from "../../ReusableComponents/Stepper/stepper";
import Button from "../../ReusableComponents/Button/button";
import StepHeader from "../../ReusableComponents/StepHeader/StepHeader";
import EducationForm from "../../ReusableComponents/EducationForm/EducationForm";
import IconButton from "../../ReusableComponents/IconButton/iconButton";
import PdfDocument from "../../ReusableComponents/PdfComponent/PdfDocument";
import {
  ButtonWraper,
  Cv,
  FormFields,
  StyleForm,
  Wraper,
  Dots,
} from "./StyledComponents";

import { useSnackbar } from "notistack";
import { Formik, Form, FieldArray } from "formik";

//Education Service
import EducationService from "../../../Services/EducationService";
import * as Yup from "yup";

const EducationSchema = Yup.object().shape({
  Education: Yup.array().of(
    Yup.object({
      id: Yup.string(),
      eduYear: Yup.number(),
      eduDiploma: Yup.string().max(200).required("Required"),
    })
  ),
});

const Education = ({
  moveToNextStep,
  file,
  setEducationArray,
  Education,
  fileFull,
  stepType,
  careerId,
  careerName,
  careerLastName,
  cvLanguage,
  setIsEducationScraped,
  isEducationScraped,
  setTimeStamp,
  spinnerStart,
  spinnerStop,
  setErrors,
  removeErrors,
  errors,
}) => {
  const [fullWidthForm, setFullWidthForm] = useState(false);
  const [localErrors, setLocalErrors] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [initialValues, setinitialValues] = useState({
    Education,
  });

  const [showErrors, setShowErrors] = useState(true);

  useEffect(() => {
    setinitialValues({ Education });
  }, [setinitialValues, Education]);

  useEffect(() => {
    if (showErrors) {
      for (let i = 0; i < localErrors.length; i++) {
        enqueueSnackbar(localErrors[i], {
          variant: "error",
        });
      }
    }
    setShowErrors(false);
  }, [enqueueSnackbar, localErrors, showErrors, setShowErrors]);

  useEffect(() => {
    const link = window.location.href;
    const length = link.split("/").length;
    const step = link.split("/")[length - 1];
    async function getData() {
      if (step === "update" && isEducationScraped === false) {
        //Get Education
        spinnerStart();
        //get education
        let education = await EducationService.get(careerId);
        if (education.responseCode >= 400) {
          //manage error
          enqueueSnackbar("Get Education - error", {
            variant: "error",
          });
        } else {
          education = education.result;
          education = education.value.map((item) => {
            return {
              eduId: item.btk_educationid,
              eduYear: item.btk_date ? item.btk_date : "",
              eduDiploma: item.btk_name ? item.btk_name : "",
            };
          });

          education = education.sort((a, b) => b.eduYear - a.eduYear);

          setinitialValues({ Education: education });
          setEducationArray(education);
        }
        spinnerStop();
      }
    }

    getData();
  }, [
    careerId,
    enqueueSnackbar,
    isEducationScraped,
    spinnerStart,
    spinnerStop,
    setEducationArray,
  ]);

  useEffect(() => {
    document.title = "Education";

    //
    // Scrap Education
    //
    const scrapEducation = async (file) => {
      let fileType = "";

      if (file.type === "application/pdf") {
        fileType = ".pdf";
      } else {
        fileType = ".docx";
      }
      const date = new Date().getTime() / 1000;

      const fileName = date.toFixed(0) + fileType;
      const code = date.toFixed(0);
      setTimeStamp(code);
      const language = cvLanguage.toLowerCase();

      let extension = "";
      if (fileType === ".pdf") {
        extension = "application/pdf";
      } else {
        extension =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      }

      const blob = file.slice(0, file.size, extension);
      const newFile = new File([blob], fileName, { type: extension });

      const data = new FormData();
      data.append("file", newFile);
      data.append("type", language);
      data.append("extension", fileType);
      data.append("code", code);

      const { result, responseCode } = await EducationService.scrap(data, code);
      if (responseCode >= 400) {
        enqueueSnackbar("Scrap Education - Error", {
          variant: "error",
        });
        return false;
      } else {
        return result;
      }
    };

    const scrapData = async () => {
      if (stepType === "importStep") {
        if (fileFull) {
          spinnerStart();
          const result = await scrapEducation(fileFull);

          if (result && result.Education) {
            const scrapedEducation = result.Education;
            let educations = [];

            if(scrapedEducation.length > 0) {
                scrapedEducation.forEach((education, index) => {
                  const model = {
                    eduId: `${education.date}-${index}`,
                    eduYear: education.date.toString(),
                    eduDiploma: education.description,
                    eduTouched: false,
                  };
                  educations.push(model);
                });
              // Reverse here to return most recent date on top
              setinitialValues({ Education: educations.reverse() });
              setEducationArray(educations);
            }
          }
          spinnerStop();
        }
      }
    };

    if (isEducationScraped === false) {
      scrapData();
      setIsEducationScraped(true);
    }
    setLocalErrors(errors);
  }, [
    stepType,
    setIsEducationScraped,
    enqueueSnackbar,
    errors,
    isEducationScraped,
    careerId,
    file,
    setEducationArray,
    careerLastName,
    careerName,
    cvLanguage,
    setTimeStamp,
    spinnerStart,
    spinnerStop,
    fileFull,
    setErrors,
    setLocalErrors,
  ]);

  const dotsClick = () => {
    setFullWidthForm(!fullWidthForm);
  };

  const stepperClicked = (values) => {
    let education = [];

    education = values.Education.sort((a, b) => b.eduYear - a.eduYear);

    setEducationArray(education);
  };

  return (
    <Wraper>
      <StyleForm fullWidthForm={fullWidthForm}>
        <FormFields>
          <Formik
            validateOnChange={true}
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={EducationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              const errors = [];
              setSubmitting(true);
              removeErrors();
              spinnerStart();

              values.Education = values.Education.sort(
                (a, b) => b.eduYear - a.eduYear
              );

              setEducationArray(values.Education);

              for (let i = 0; i < values.Education.length; i++) {

                if(values.Education[i].eduDiploma.toString().length > 200){
                  errors.push("The maximum size is 200 characters");
                }
                if (values.Education[i].eduId.toString().length < 10) {
                  //add

                  const model = {
                    education: {
                      name: values.Education[i].eduDiploma
                        ? values.Education[i].eduDiploma
                        : "",
                      date: values.Education[i].eduYear
                        ? values.Education[i].eduYear
                        : "",
                      careerId,
                    },
                  };

                  const { result, responseCode } = await EducationService.send(
                    model
                  );

                  const data = result;
                  if (responseCode >= 400) {
                    errors.push("Add education - Error");
                  } else {
                    values.Education[i].eduId = data.btk_educationid;
                  }
                } else {
                  //update
                  const model = {
                    education: {
                      id: values.Education[i].eduId,
                      name: values.Education[i].eduDiploma,
                      date: values.Education[i].eduYear,
                      careerId,
                    },
                  };

                  const { responseCode } = await EducationService.update(
                    model,
                    values.Education[i].eduId
                  );
                  if (responseCode >= 400) {
                    errors.push("Update education - Error");
                  }
                }
              }

              setErrors(errors);
              setSubmitting(false);
              spinnerStop();
              moveToNextStep(4);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <>
                <Stepper
                  StepNumber={1}
                  onClick={() => stepperClicked(values)}
                  isUpdate={stepType === "updateStep"}
                />
                <StepHeader>
                  Here are the informations concerning your candidate education
                  we had on the CV
                </StepHeader>
                <Form autoComplete="off">
                  <FieldArray name="Education">
                    {({ push, remove }) => (
                      <>
                        {values.Education &&
                          values.Education.length > 0 &&
                          values.Education.map((edu, index) => (
                            <div key={index}>
                              <EducationForm
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                edu={edu}
                                number={index}
                                error={
                                  errors.Education && errors.Education[index]
                                }
                                touched={
                                  touched.Education && touched.Education[index]
                                }
                                remove={remove}
                              />
                            </div>
                          ))}
                        <ButtonWraper>
                          <IconButton
                            Plus
                            text={"Add an diploma"}
                            marginRight={30}
                            handleClick={() =>
                              push({
                                eduId: values.Education
                                  ? values.Education.length
                                  : 0,
                                eduYear: "",
                                eduDiploma: "",
                              })
                            }
                          />
                        </ButtonWraper>
                      </>
                    )}
                  </FieldArray>

                  <ButtonWraper>
                    <Button
                      height={30}
                      width={120}
                      text={"Previous"}
                      submit={true}
                      fontSize={16}
                      marginLeft={30}
                      handleClick={(event) => {
                        let education = [];
                        education = values.Education.sort(
                          (a, b) => b.eduYear - a.eduYear
                        );

                        setEducationArray(education);
                        event.preventDefault();
                        moveToNextStep(2);
                      }}
                    />
                    <Button
                      height={30}
                      width={120}
                      text={"Next"}
                      left
                      submit={true}
                      fontSize={16}
                      marginRight={30}
                      handleClick={() => {
                        if (Object.keys(errors).length !== 0) {
                          for(let i = 0; i < errors.Education.length; i++)
                          {
                            if(errors.Education[i]){
                              console.log(errors);
                              enqueueSnackbar(
                                errors.Education[i].eduDiploma ? errors.Education[i].eduDiploma : "Something goes wrong in form!",
                                {
                                  variant: "error",
                                }
                              );
                            }
                          }
                        }
                      }}
                    />
                  </ButtonWraper>
                </Form>
              </>
            )}
          </Formik>
        </FormFields>

        {stepType === "importStep" && <Dots onClick={dotsClick} />}
      </StyleForm>
      {!fullWidthForm && stepType === "importStep" && (
        <Cv>
          <PdfDocument file={file} />
        </Cv>
      )}
    </Wraper>
  );
};

const mapStateToProps = (state) => {
  return {
    stepType: state.Stepper.stepType,
    errors: state.Errors.errors,
    ScrapedData: state.CvGenerator.scrapedData,
    file: state.CvGenerator.cvFile,
    fileFull: state.CvGenerator.cvFilePdf,
    Education: state.Education.Education,
    careerId: state.Career.careerId,
    careerName: state.Career.firstName,
    careerLastName: state.Career.lastName,
    cvLanguage: state.CvGenerator.cvLanguage,
    isEducationScraped: state.Stepper.isEducationScraped,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setErrors: (data) => dispatch(setErrors(data)),
    removeErrors: () => dispatch(removeErrors()),
    moveToNextStep: (data) => dispatch(moveToNextStep(data)),
    setEducationArray: (data) => dispatch(setEducationArray(data)),
    setIsEducationScraped: (data) => dispatch(setIsEducationScraped(data)),
    setTimeStamp: (data) => dispatch(setTimeStamp(data)),
    spinnerStart: () => dispatch(spinnerStart()),
    spinnerStop: () => dispatch(spinnerStop()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Education);
