import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  moveToNextStep,
  setHobbies,
  setIsHobbiesScraped,
  spinnerStart,
  spinnerStop,
  setErrors,
  removeErrors,
} from "../../../Redux/Actions/Actions";

//Formik
import { Formik, Form } from "formik";

//components
import Stepper from "../../ReusableComponents/Stepper/stepper";
import Button from "../../ReusableComponents/Button/button";
import PdfDocument from "../../ReusableComponents/PdfComponent/PdfDocument";
import StepHeader from "../../ReusableComponents/StepHeader/StepHeader";
import Textfield from "../../ReusableComponents/TextFieldChip/TextFieldChip";
import {
  InputWraper,
  ButtonWraper,
  Cv,
  FormFields,
  StyleForm,
  Wraper,
  Dots,
} from "./StyledComponents";
//Service
import CareerService from "../../../Services/CareerService";
import { useSnackbar } from "notistack";
const Education = ({
  moveToNextStep,
  file,
  hobbies,
  setHobbies,
  setIsHobbiesScraped,
  isHobbiesScraped,
  careerId,
  careerName,
  careerLastName,
  cvLanguage,
  cvTimeStamp,
  spinnerStart,
  spinnerStop,
  fileFull,
  setErrors,
  removeErrors,
  errors,
  stepType,
}) => {
  const [fullWidthForm, setFullWidthForm] = useState(false);
  const [hobby, setHobby] = useState("");
  //const [localErrors, setLocalErrors] = useState([]);
  // const [showErrors, setShowErrors] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  //Get Data - Update Step
  useEffect(() => {
    async function getData() {
      if (stepType === "updateStep" && isHobbiesScraped === false) {
        spinnerStart();
        setIsHobbiesScraped(true);
        let hobbiesList = "";
        //hobbies
        const hobbies = await CareerService.getHobbies(careerId);
        if (hobbies.responseCode >= 400) {
          //manage error
          enqueueSnackbar("Get Hobbies - error", {
            variant: "error",
          });
        } else {
          hobbiesList = hobbies.result.value[0].btk_hobbies.split(', ').join(',').split(',').join(',');
          setHobby({ hobbies: hobbiesList });
          setIsHobbiesScraped(true);
        }
        spinnerStop();
      }
    }
    getData();
  }, [
    hobbies,
    careerId,
    enqueueSnackbar,
    stepType,
    setIsHobbiesScraped,
    isHobbiesScraped,
    spinnerStop,
    spinnerStart,
  ]);

  // Set hobbies
  useEffect(() => {
    setHobby({ hobbies: hobbies });
  }, [setHobby, hobbies]);

  // //Hobbies form
  // const hobbiesChange = (e) => {
  //   setHobbies(e.target.value);
  //   setHobby(e.target.value);
  // };

  // show errors
  // useEffect(() => {
  //   if (localErrors) {
  //     for (let i = 0; i < localErrors.length; i++) {
  //       enqueueSnackbar(localErrors[i], {
  //         variant: "error",
  //       });
  //     }
  //   }
  //   setShowErrors(false);
  // }, [localErrors, enqueueSnackbar, showErrors, setShowErrors]);

  // Scrap hobbies
  useEffect(() => {
    document.title = "Hobbies";
    //setLocalErrors(errors);
    const scrapData = async () => {
      spinnerStart();
      if (!isHobbiesScraped) {
        //scrap
        const fileFormatType = fileFull.type;
        let fileType = "";
        if (fileFormatType === "application/pdf") {
          fileType = ".pdf";
        } else {
          fileType = ".docx";
        }

        const code = cvTimeStamp;
        const language = cvLanguage.toLowerCase();

        const data = new FormData();
        data.append("type", language);
        data.append("extension", fileType);
        data.append("code", code);
        const { result, responseCode } = await CareerService.scrapHobbies(
          data,
          code
        );
        if (responseCode >= 400) {
          enqueueSnackbar("Scrap Hobbies - error", {
            variant: "error",
          });
        } else {
          if(result && result.hobbys){
            setHobby({ hobbies: result.hobbys.join(",")});
          }
          
          setIsHobbiesScraped(true);
        }
      }
      spinnerStop();
    };
    if (stepType === "importStep") {
      scrapData();
    }
  }, [
    stepType,
    enqueueSnackbar,
    careerId,
    careerLastName,
    careerName,
    cvLanguage,
    cvTimeStamp,
    file,
    isHobbiesScraped,
    setHobbies,
    setIsHobbiesScraped,
    spinnerStart,
    spinnerStop,
    fileFull,
    errors,
  ]);

  //handle submit
  // const handleNextClick = async () => {
  //   spinnerStart();
  //   removeErrors();

  //   const model = {
  //     hobbies: hobby,
  //     careerId,
  //     type: "HOBBIES",
  //   };

  //   const { responseCode } = await CareerService.sendHobbies(model);
  //   if (responseCode >= 400) {
  //     const errorArray = [];
  //     errorArray.push("Send Hobbies - Error");
  //     setErrors(errorArray);
  //   }

  //   spinnerStop();
  //   moveToNextStep(7);
  // };

  const dotsClick = () => {
    setFullWidthForm(!fullWidthForm);
  };
  const stepperClicked = (values) => {
    setIsHobbiesScraped(true);
    setHobbies(values.hobbies.split(', ').join(',').split(',').join(','));
  };
  return (
    <Wraper>
      <StyleForm fullWidthForm={fullWidthForm}>
        <FormFields>
          <Formik
            initialValues={hobby}
            enableReinitialize={true}
            onSubmit={async (values, { setSubmitting }) => {
              spinnerStart();
              removeErrors();

              const model = {
                hobbies: values.hobbies ? values.hobbies : "",
                careerId,
                type: "HOBBIES",
              };
              setHobbies(values.hobbies);

              const { responseCode } = await CareerService.sendHobbies(model);
              if (responseCode >= 400) {
                const errorArray = [];
                errorArray.push("Send Hobbies - Error");
                setErrors(errorArray);
              }

              setIsHobbiesScraped(true);
              spinnerStop();
              moveToNextStep(7);
            }}
          >
            {({
              values,

              setFieldValue,
            }) => (
              <>
                <Stepper
                  StepNumber={4}
                  onClick={() => stepperClicked(values)}
                  isUpdate={stepType === "updateStep"}
                />
                <StepHeader>
                  Here are the candidate's hobbies. You can complete it if
                  needed.
                </StepHeader>
                <Form autoComplete="off">
                  <InputWraper>
                    <Textfield
                      name="hobbies"
                      label={"Hobbies"}
                      multiline={true}
                      rows={6}
                      value={values.hobbies}
                      variant={"outlined"}
                      setFieldValue={setFieldValue}
                    />
                  </InputWraper>
                  <ButtonWraper>
                    <Button
                      height={30}
                      width={120}
                      text={"Previous"}
                      submit={true}
                      fontSize={16}
                      marginLeft={30}
                      handleClick={(event) => {
                        event.preventDefault();
                        setHobbies(values.hobbies);
                        moveToNextStep(5);
                      }}
                    />
                    <Button
                      height={30}
                      width={120}
                      text={"Next"}
                      left
                      fontSize={16}
                      marginRight={30}
                      submit={true}
                    />
                  </ButtonWraper>
                </Form>
              </>
            )}
          </Formik>
        </FormFields>

        {stepType === "importStep" && <Dots onClick={dotsClick} />}
      </StyleForm>

      {!fullWidthForm && stepType === "importStep" && (
        <Cv>
          <PdfDocument file={file} />
        </Cv>
      )}
    </Wraper>
  );
};

const mapStateToProps = (state) => {
  return {
    stepType: state.Stepper.stepType,
    errors: state.Errors.errors,
    scrapedData: state.CvGenerator.scrapedData,
    file: state.CvGenerator.cvFile,
    fileFull: state.CvGenerator.cvFilePdf,
    hobbies: state.Hobbies.hobbies,
    isHobbiesScraped: state.Stepper.isHobbiesScraped,
    careerId: state.Career.careerId,
    careerName: state.Career.firstName,
    careerLastName: state.Career.lastName,
    cvLanguage: state.CvGenerator.cvLanguage,
    cvTimeStamp: state.CvGenerator.cvTimeStamp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setErrors: (data) => dispatch(setErrors(data)),
    removeErrors: () => dispatch(removeErrors()),
    moveToNextStep: (data) => dispatch(moveToNextStep(data)),
    setHobbies: (data) => dispatch(setHobbies(data)),
    setIsHobbiesScraped: (data) => dispatch(setIsHobbiesScraped(data)),
    spinnerStart: () => dispatch(spinnerStart()),
    spinnerStop: () => dispatch(spinnerStop()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Education);
