import AuthController from '../AuthProvider/AuthController';

class AuthService {
  async getContactInformations(id) {
    const token = await AuthController.getToken();
    let response = await fetch(
      process.env.REACT_APP_API_URL + "/api/contact?id=" + id,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
      }
    );

    return await response.json();
  }
}

export default new AuthService();
