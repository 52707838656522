import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import ReactDOM from "react-dom";

const Spinner = styled(CircularProgress)`
  margin-top: auto;
  margin-bottom: auto;
`;

const BackGround = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  opacity: 0.7;
  background-color: black;
`;

const Wraper = styled.div`
  z-index: 3;
  width: 200px;
  height: 60px;
  background-color: white;
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  opacity: 1;
  z-index: 5;
  justify-content: center;
  border-radius: 10px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#00FF00",
  },
}));

export default function Loader() {
  const classes = useStyles();

  return ReactDOM.createPortal(
    <>
      <BackGround />
      <Wraper>
        <Spinner className={classes.root} />
      </Wraper>
    </>,
    document.getElementById("spinner")
  );
}
