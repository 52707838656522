import React from "react";
import MainContent from "./components/MainContent/mainContent";
import "./App.css";
//components
import { SnackbarProvider } from "notistack";
import SubContent from "./components/MainContent/subContent";
//msal
import { authProvider } from "./AuthProvider/authProvider";
import { AuthenticationState } from "react-aad-msal";
import { AzureAD } from "react-aad-msal";

//store
import store from "./Redux/Reducers/combineReducers";

const App = () => {
  document.title = "Bertek - Cocomilk";

  return (
    <AzureAD provider={authProvider} forceLogin={true} reduxStore={store}>
      {({ authenticationState, error, accountInfo }) => {
        return (
          <SnackbarProvider maxSnack={5}>
            {authenticationState === AuthenticationState.Authenticated && (
              <>
                <MainContent error={error} accountInfo={accountInfo} />
              </>
            )}
            <SubContent />
          </SnackbarProvider>
        );
      }}
    </AzureAD>
  );
};

export default App;
