import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  moveToNextStep,
  addExperience,
  removeExperience,
  updateExperience,
  yearsOfExperienceChange,
  titleOfCvChange,
  spinnerStart,
  spinnerStop,
  setIsExperienceScraped,
  setTimeStamp,
  setErrors,
  removeErrors,
  setCareerBasicInfo,
  setStepType,
  setStep,
} from "../../../Redux/Actions/Actions";

//Formik
import { Formik, Form, FieldArray } from "formik";

//components
import Stepper from "../../ReusableComponents/Stepper/stepper";
import Button from "../../ReusableComponents/Button/button";
import StepHeader from "../../ReusableComponents/StepHeader/StepHeader";
import Input from "../../ReusableComponents/TextInput/textInput";
import ExperienceForm from "../../ReusableComponents/ExpierienceForm/ExperienceForm";
import IconButton from "../../ReusableComponents/IconButton/iconButton";
import PdfDocument from "../../ReusableComponents/PdfComponent/PdfDocument";
import DialogBox from "../../ReusableComponents/DialogBox/DialogBox";

import {
  InputWraper,
  FormLineWraper,
  ButtonWraper,
  Cv,
  FormFields,
  StyleForm,
  ErrorMessage,
  Wraper,
  Dots,
} from "./StyledComponents";
//Services
import ExperienceService from "../../../Services/ExperienceService";
import CareerService from "../../../Services/CareerService";
import AuthController from "../../../AuthProvider/AuthController";

//snackbar and schema
import { useSnackbar } from "notistack";
import { filterExperience } from "./HelperFunctions";
import { experienceSchema } from "./HelperFunctions";

const Experiences = ({
  moveToNextStep,
  file,
  Experiences,
  ScrapedData,
  updateExperience,
  yearsOfExperienceChange,
  titleOfCvChange,
  spinnerStart,
  spinnerStop,
  fileFull,
  cvLanguage,
  cvTimeStamp,
  setIsExperienceScraped,
  careerId,
  isExperienceScraped,
  setTimeStamp,
  titlecv,
  yearsOfExp,
  setErrors,
  removeErrors,
  errors,
  setCareerBasicInfo,
  setStep,
  setStepType,
  stepType,
  outputLanguage,
}) => {
  const [fullWidthForm, setFullWidthForm] = useState(false);
  const [numberOfExperienceError, setNumberOfExperienceError] = useState(false);
  const [initialValues, setInitialValues] = useState({
    titlecv,
    yearOfExperience: yearsOfExp,
    Experiences,
  });
  const [countries, setCountries] = useState([]);
  const [localErrors, setLocalErrors] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [showErrors, setShowErrors] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isRender, setIsRender] = useState(false);
  useEffect(() => {
    async function getCountries() {
      const { result, responseCode } = await CareerService.getCountries(outputLanguage);
      if (responseCode >= 400) {
        //error
        enqueueSnackbar("Fetch countries - Error", {
          variant: "error",
        });
      } else {
        setCountries(result);
      }
    }

    getCountries();
  }, [setCountries, enqueueSnackbar, outputLanguage]);


  //Check if there is data for this career
  useEffect(() => {
    async function getData() {
      if (isExperienceScraped === false) {
        setStepType("importStep");
      }
      // GeT - career basic
      if (careerId) {
        const careerResult = await CareerService.getCareerInfoBasic(careerId);

        if (careerResult.responseCode >= 400) {
          enqueueSnackbar("Get Career basic info - error", {
            variant: "error",
          });
        } else {
          if (careerResult.result.cvtitle !== null) {
            if (careerResult.result.cvtitle.length !== 0) {
              setDialogOpen(true);
            }
          }
        }
      }
    }

    getData();
  }, [careerId, setDialogOpen, enqueueSnackbar, spinnerStop, spinnerStart, setStepType, isExperienceScraped,]);


  useEffect(() => {
    async function getData() {
      const link = window.location.href;
      const length = link.split("/").length;
      const careerId = link.split("/")[length - 2];
      const step = link.split("/")[length - 1];
      if (step === "update") {
        setFullWidthForm(true);
      }
      if (step === "update" && isExperienceScraped === false) {
        spinnerStart();
        setStepType("updateStep");
        let init = {
          titlecv: "",
          yearOfExperience: "",
          Experiences: [],
        };

        // GeT - career basic
        const careerResult = await CareerService.getCareerInfoBasic(careerId);

        const careerResponseCode = careerResult.responseCode;
        if (careerResponseCode >= 400) {
          enqueueSnackbar("Get Career basic info - error", {
            variant: "error",
          });
          setStep(0);
          spinnerStop();
        } else {
          setCareerBasicInfo(careerResult.result);
          const basic = careerResult.result;

          init.titlecv = basic.cvtitle;
          init.yearOfExperience = basic.yearsOfExperience;

          //GET - Experience
          const xpResult = await ExperienceService.get(careerId, outputLanguage);

          if (xpResult.responseCode >= 400) {
            //manage error
            enqueueSnackbar("Get Experience - error", {
              variant: "error",
            });
          } else {
            init.Experiences = filterExperience(xpResult.result);
          }

          setInitialValues(init);
          setIsRender(true);
          spinnerStop();
        }
      } else if (isExperienceScraped === false) {
        setStepType("importStep");
      }
    }

    //Get for update
    getData();
  }, [
    enqueueSnackbar,
    isExperienceScraped,
    setCareerBasicInfo,
    setStepType,
    spinnerStart,
    spinnerStop,
    setStep,
    outputLanguage,
  ]);

  useEffect(() => {
    document.title = "Experiences";

    const scrapExperiences = async (file) => {
      let fileType = "";

      if (file.type === "application/pdf") {
        fileType = ".pdf";
      } else {
        fileType = ".docx";
      }
      const date = new Date().getTime() / 1000;

      const fileName = date.toFixed(0) + fileType;
      const code = date.toFixed(0);
      setTimeStamp(code);
      const language = cvLanguage.toLowerCase();

      let extension = "";
      if (fileType === ".pdf") {
        extension = "application/pdf";
      } else {
        extension =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      }

      const blob = file.slice(0, file.size, extension);
      const newFile = new File([blob], fileName, { type: extension });

      const data = new FormData();
      data.append("file", newFile);
      data.append("type", language);
      data.append("extension", fileType);
      data.append("code", code);

      const { result, responseCode } = await ExperienceService.scrap(data, code, outputLanguage);
      if (responseCode >= 400) {
        enqueueSnackbar("Scrap Education - Error", {
          variant: "error",
        });
        return false;
      } else {
        return result;
      }
    };

    const scrapData = async () => {
      const link = window.location.href;
      const length = link.split("/").length;
      const step = link.split("/")[length - 1];

      if (step === "import") {
        if (fileFull) {
          spinnerStart();
          const result = await scrapExperiences(fileFull);

          if (result && result.value && result.value.length !== 0) {
            let init = {
              titlecv: "",
              yearOfExperience: "",
              Experiences: [],
            };

            if(Array.isArray(result.value)) {
              init.Experiences = filterExperience(result);
            }
            
            setInitialValues(init);
            setIsRender(true);
          }
          spinnerStop();
        }
      }
    };

    if (isExperienceScraped === false) {
      scrapData();
      setIsExperienceScraped(true);
    }
    setLocalErrors(errors);
  }, [
    stepType,
    setIsExperienceScraped,
    enqueueSnackbar,
    errors,
    isExperienceScraped,
    careerId,
    file,
    updateExperience,
    cvLanguage,
    setTimeStamp,
    spinnerStop,
    spinnerStart,
    fileFull,
    setErrors,
    setLocalErrors,
    outputLanguage,
  ]);

  //Errors
  useEffect(() => {
    document.title = "Experiences";
    setLocalErrors(errors);
  }, [errors]);

  //Display errors
  useEffect(() => {
    if (showErrors) {
      for (let i = 0; i < localErrors.length; i++) {
        enqueueSnackbar(localErrors[i], {
          variant: "error",
        });
      }
    }
    setShowErrors(false);
  }, [enqueueSnackbar, localErrors, showErrors, setShowErrors]);

  const dotsClick = () => {
    setFullWidthForm(!fullWidthForm);
  };
  const stepperClicked = (values) => {
    updateExperience(values.Experiences);
    titleOfCvChange(values.titlecv);
    yearsOfExperienceChange(values.yearOfExperience);
    setIsExperienceScraped(true);
  };

  return (
    <Wraper>
      <StyleForm full={fullWidthForm}>
        <FormFields>
          <Formik
            validateOnChange={true}
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={experienceSchema}
            onSubmit={async (values, { setSubmitting }) => {
              // refresh token before send datas
              await AuthController.refreshToken();
              if (values.Experiences.length === 0) {
                setNumberOfExperienceError(true);
              } else {
                setSubmitting(true);
                const sortedList = values.Experiences.sort((a, b) => {
                  return new Date(a.to) - new Date(b.to);
                });
                updateExperience(sortedList.reverse());
                titleOfCvChange(values.titlecv);
                yearsOfExperienceChange(values.yearOfExperience);
                spinnerStart();
                removeErrors();

                for (let i = 0; i < values.Experiences.length; i++) {
                  const exp = values.Experiences[i];

                  if (exp.currentJob === true) {
                    exp.to = new Date();
                  }
                  let to = new Date(exp.to);
                  to = `${to.getMonth() + 1}/01/${to.getFullYear()}`;

                  let from = new Date(exp.from);
                  from = `${from.getMonth() + 1}/01/${from.getFullYear()}`;

                  const model = {
                    experience: {
                      from: from,
                      to: to,
                      currentJob: exp.currentJob,
                      company: exp.company,
                      client: exp.client,
                      city: exp.city,
                      country: exp.country,
                      technicalenvironment: exp.technical,
                      description: exp.jobDescription,
                      name: exp.jobTitle,
                    },
                    careerId,
                  };

                  //Send or update

                  const errors = [];

                  if (values.Experiences[i].id.toString().length < 10) {
                    const {
                      result,
                      responseCode,
                    } = await ExperienceService.send(model, outputLanguage);
                    const data = result;

                    if (responseCode >= 400) {
                      errors.push("Add Experience - failed");
                    }

                    values.Experiences[i].id = data.btk_experienceid;
                    // const sortedList = values.Experiences.sort((a, b) => {
                    //   return new Date(a.to) - new Date(b.to);
                    // });

                    // const reverseList = sortedList.reverse();

                    updateExperience(values.Experiences);
                  } else {
                    const { responseCode } = await ExperienceService.update(
                      model,
                      values.Experiences[i].id,
                      outputLanguage
                    );

                    if (responseCode >= 400) {
                      errors.push("Update Experience - failed");
                    }
                  }

                  const titleModel = {
                    cvtitle: values.titlecv,
                    yearsOfExperience: values.yearOfExperience,
                    type: "EXPERIENCE",
                    careerId,
                  };

                  const { responseCode } = await CareerService.sendHobbies(
                    titleModel
                  );
                  if (responseCode >= 400) {
                    errors.push("Update Career - failed");
                  }
                  setErrors(errors);
                }

                setIsExperienceScraped(true);
                spinnerStop();
                setSubmitting(false);
                moveToNextStep(3);
              }
            }}
          >
            {({
              errors,
              touched,
              values,
              setFieldValue,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => (
              <>
                <Stepper
                  StepNumber={0}
                  isUpdate={stepType === "updateStep"}
                  onClick={() => stepperClicked(values)}
                />
                <StepHeader>
                  Here are the experiences we got from your candidate CV. You
                  can now complete it
                </StepHeader>
                <Form autoComplete={"off"}>
                  <FormLineWraper>
                    <InputWraper width={60}>
                      <Input
                        handleBlur={handleBlur}
                        name={"titlecv"}
                        error={touched.titlecv && !!errors.titlecv}
                        label={"Title of CV"}
                        variant={"outlined"}
                        value={values.titlecv ? values.titlecv : ""}
                        valueChange={handleChange}
                      />
                    </InputWraper>
                    <InputWraper width={35}>
                      <Input
                        handleBlur={handleBlur}
                        name={"yearOfExperience"}
                        error={
                          touched.yearOfExperience && !!errors.yearOfExperience
                        }
                        label={"Years of experiences"}
                        variant={"outlined"}
                        value={
                          values.yearOfExperience ? values.yearOfExperience : ""
                        }
                        valueChange={handleChange}
                      />
                    </InputWraper>
                  </FormLineWraper>
                  <FieldArray name="Experiences">
                    {({ push, remove }) => (
                      <>
                        {" "}
                        {values.Experiences &&
                          values.Experiences.length > 0 &&
                          values.Experiences.map((xp, index) => (
                              <ExperienceForm
                                key={xp.id}
                                isRender={isRender}
                                isExperienceScraped={isExperienceScraped}
                                handleBlur={handleBlur}
                                Experiences={values.Experiences}
                                experience={xp}
                                number={index}
                                setFieldValue={setFieldValue}
                                countries={countries}
                                errors={
                                  errors.Experiences &&
                                  errors.Experiences[index]
                                }
                                touched={
                                  touched.Experiences &&
                                  touched.Experiences[index]
                                }
                                remove={remove}
                                handleChange={handleChange}
                              />
                          ))}
                        <ButtonWraper>
                          <IconButton
                            Plus
                            text={"Add an experience"}
                            marginRight={30}
                            handleClick={() => {
                                let id = 0;
                                if(values.Experiences && values.Experiences.length) {
                                  let i = values.Experiences.find((xp) => xp.id === values.Experiences.length);
                                  if(i) {
                                    id = values.Experiences.length + 10;
                                  } else {
                                    id = values.Experiences.length;
                                  }
                                }
                                push({
                                  id: id, // values.Experiences ? values.Experiences.length : 0,
                                  from: "",
                                  to: "",
                                  company: "",
                                  client: "",
                                  city: "",
                                  country: "",
                                  jobTitle: "",
                                  jobDescription: "",
                                  technical: "",
                                  currentJob: false,
                                })
                              }
                            }
                          />
                        </ButtonWraper>
                        <ButtonWraper>
                          <Button
                            height={30}
                            width={120}
                            text={"Next"}
                            submit={true}
                            left
                            fontSize={16}
                            marginRight={30}
                            handleClick={() => {
                              if (Object.keys(errors).length !== 0) {
                                enqueueSnackbar(
                                  "Upss... There is problem in form",
                                  {
                                    variant: "error",
                                  }
                                );
                              }
                            }}
                          />
                        </ButtonWraper>
                      </>
                    )}
                  </FieldArray>
                </Form>
              </>
            )}
          </Formik>

          {numberOfExperienceError && (
            <ErrorMessage>Please add minimum one experience</ErrorMessage>
          )}
        </FormFields>
        {stepType === "importStep" && <Dots onClick={dotsClick} />}
        {stepType === "importStep" && <DialogBox isOpen={dialogOpen} />}
      </StyleForm>

      {!fullWidthForm && stepType === "importStep" && (
        <Cv>
          <PdfDocument file={file} />
        </Cv>
      )}
    </Wraper>
  );
};

const mapStateToProps = (state) => {
  return {
    stepType: state.Stepper.stepType,
    errors: state.Errors.errors,
    titlecv: state.Experience.titleOfCV,
    yearsOfExp: state.Experience.yearsOfExperience,
    file: state.CvGenerator.cvFile,
    fileFull: state.CvGenerator.cvFilePdf,
    Experiences: state.Experience.Experiences,
    careerId: state.Career.careerId,
    cvLanguage: state.CvGenerator.cvLanguage,
    outputLanguage: state.CvGenerator.cvOutputLanguage,
    isExperienceScraped: state.Stepper.isExperienceScraped,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStep: (data) => dispatch(setStep(data)),
    setStepType: (data) => dispatch(setStepType(data)),
    setCareerBasicInfo: (data) => dispatch(setCareerBasicInfo(data)),
    setErrors: (data) => dispatch(setErrors(data)),
    removeErrors: () => dispatch(removeErrors()),
    moveToNextStep: (data) => dispatch(moveToNextStep(data)),
    addExperience: () => dispatch(addExperience()),
    removeExperience: (data) => dispatch(removeExperience(data)),
    titleOfCvChange: (data) => dispatch(titleOfCvChange(data)),
    yearsOfExperienceChange: (data) => dispatch(yearsOfExperienceChange(data)),
    setIsExperienceScraped: (data) => dispatch(setIsExperienceScraped(data)),
    setTimeStamp: (data) => dispatch(setTimeStamp(data)),
    spinnerStart: () => dispatch(spinnerStart()),
    spinnerStop: () => dispatch(spinnerStop()),
    updateExperience: (data) => dispatch(updateExperience(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Experiences);
