import styled from "styled-components";
import React from "react";
import { connect } from "react-redux";
import { spinnerStart, spinnerStop } from "../../../Redux/Actions/Actions";

//components
import { ErrorMessage } from '../../CvGenerator/Experiences/StyledComponents';
import Input from "../TextInput/textInput";
import IconButton from "../IconButton/iconButton";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import Checkbox from "../Checkbox/Checkbox";
import AutoComplete from "../DownshiftSelect/DownshiftSelect";
import InputChip from "../TextFieldChip/TextFieldChip";
import RichText from "../RichTextEditor/RichText";
import { useSnackbar } from "notistack";

//Experience Service
import ExperienceService from "../../../Services/ExperienceService";

//Styled input
const InputWraper = styled.div`
  display: flex;
  flex-wrap: nowrap;

  margin-left: 10px;
  margin-right: 10px;
  width: ${({ width }) => width}%;
`;

const CheckboxInputWraper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  width: ${({ width }) => width}%;
`;

const FormLineWraper = styled.div`
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;
const Wraper = styled.div`
  width: 100%;
`;
const IconWraper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const ExperienceForm = ({
  experience,
  number,
  errors,
  touched,
  handleChange,
  remove,
  Experiences,
  handleBlur,
  setFieldValue,
  spinnerStart,
  spinnerStop,
  countries,
  isExperienceScraped,
  isRender,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const removeExperiences = async () => {
    spinnerStart();
    const id = experience.id;

    if (id !== null) {
      if (id.length > 10) {
        const isSuccess = await ExperienceService.remove(id);

        if (isSuccess) {
          enqueueSnackbar("Removed Experience - from CRM", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Remove Experience - Error", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("Removed Experience", {
          variant: "success",
        });
      }
    }
    spinnerStop();
  };

  return (
    <Wraper>
      <FormLineWraper>
        <InputWraper width={25}>
          <CustomDatePicker
            name={`Experiences[${number}].from`}
            big
            handleBlur={handleBlur}
            error={touched && errors && !!errors.from && touched.from}
            date={experience.from}
            text={"From"}
            setDate={setFieldValue}
          />
        </InputWraper>
        <InputWraper width={25}>
          <CustomDatePicker
            handleBlur={handleBlur}
            name={`Experiences[${number}].to`}
            big
            error={
              touched &&
              errors &&
              experience.currentJob !== true &&
              !!errors.to &&
              touched.to
            }
            date={experience.currentJob ? new Date() : experience.to}
            text={"To"}
            setDate={setFieldValue}
          />
        </InputWraper>
        <CheckboxInputWraper width={20}>
          <Checkbox
            name={`Experiences[${number}].currentJob`}
            value={experience.currentJob}
            handleChange={handleChange}
          />
        </CheckboxInputWraper>
        <InputWraper width={30}>
          <Input
            name={`Experiences[${number}].company`}
            label={"Company"}
            variant={"outlined"}
            valueChange={handleChange}
            value={experience.company}
          />
        </InputWraper>
      </FormLineWraper>
      { errors && (errors.from || errors.to) && experience.to && experience.from && (
            <ErrorMessage style={{ textAlign: "left", marginLeft: "2rem" }}>The start date can't be after the end date</ErrorMessage>
          )}
      <FormLineWraper>
        <InputWraper width={35}>
          <Input
            handleBlur={handleBlur}
            error={errors && touched && !!errors.client && touched.client}
            name={`Experiences[${number}].client`}
            label={"Client"}
            variant={"outlined"}
            valueChange={handleChange}
            value={experience.client}
          />
        </InputWraper>
        <InputWraper width={35}>
          <Input
            name={`Experiences[${number}].city`}
            label={"City"}
            variant={"outlined"}
            valueChange={handleChange}
            value={experience.city}
          />
        </InputWraper>
        <InputWraper width={30}>
          <AutoComplete
            name={`Experiences[${number}].country`}
            label={"Country"}
            variant={"outlined"}
            valueChange={handleChange}
            data={experience.country}
            values={countries}
            setFieldValue={setFieldValue}
          />
        </InputWraper>
      </FormLineWraper>

      <FormLineWraper>
        <InputWraper width={100}>
          <Input
            handleBlur={handleBlur}
            error={errors && touched && !!errors.jobTitle && touched.jobTitle}
            name={`Experiences[${number}].jobTitle`}
            label={"Job Title"}
            variant={"outlined"}
            valueChange={handleChange}
            value={experience.jobTitle}
          />
        </InputWraper>
      </FormLineWraper>
      <FormLineWraper>
        <InputWraper width={100} style={{ height: "200px" }}>
          <RichText
            isRender={isRender}
            setFieldValue={setFieldValue}
            name={`Experiences[${number}].jobDescription`}
            rawJsText={experience.jobDescription}
            isExperienceScraped={isExperienceScraped}
            handleBlur={handleBlur}
            error={
              errors &&
              touched &&
              !!errors.jobDescription &&
              touched.jobDescription
            }
          />
        </InputWraper>
      </FormLineWraper>
      <FormLineWraper>
        <InputWraper width={100}>
          <InputChip
            name={`Experiences[${number}].technical`}
            label={"Technical Enviroment"}
            rows={6}
            multiline={true}
            variant={"outlined"}
            valueChange={handleChange}
            value={experience.technical}
            setFieldValue={setFieldValue}
          />
        </InputWraper>
      </FormLineWraper>
      <IconWraper>
        <IconButton
          Bin
          text={"Remove this experience"}
          width={200}
          handleClick={() => {
            remove(number);
            removeExperiences();
          }}
        />
      </IconWraper>
    </Wraper>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    spinnerStart: () => dispatch(spinnerStart()),
    spinnerStop: () => dispatch(spinnerStop()),
  };
};

export default connect(null, mapDispatchToProps)(ExperienceForm);
