import React, { useCallback } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";

const CssTextField = withStyles({
  root: {
    width: "100%",
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
})(TextField);

export default function AutoCompleteMultiSelect({
  label,
  name,
  variant,
  value,
  error,
  handleBlur,
  setFieldValue,
  businessSectors,
}) {
  const getValues = useCallback(() => {
    let indexs = [];

    if (value) {
      for (let j = 0; j < value.length; j++) {
        for (let i = 0; i < businessSectors.length; i++) {
          if (value[j].title === businessSectors[i].title) {
            const isExist = indexs.find(
              (item) => item.title === businessSectors[i].title
            );
            if (!isExist) {
              indexs.push(businessSectors[i]);
            }
          }
        }
      }
    }

    return indexs;
  }, [value, businessSectors]);

  return (
    <Autocomplete
      multiple
      id="tags-standard"
      onChange={(e, value) => {
        setFieldValue(name, value);
      }}
      value={getValues()}
      options={businessSectors}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <CssTextField
          {...params}
          variant={variant}
          onBlur={handleBlur}
          error={error}
          name={name}
          label={label}
        />
      )}
    />
  );
}

export const list = [
  {
    title: "Accounting",
  },
  {
    title: "Comptabilité",
  },
  {
    title: "Księgowość",
  },
  {
    title: "Agriculture",
  },
  {
    title: "Agriculture",
  },
  {
    title: "Rolnictwo",
  },
  {
    title: "Architecture",
  },
  {
    title: "Architecture",
  },
  {
    title: "Architektura",
  },
  {
    title: "Arts and Crafts",
  },
  {
    title: "Artisanat",
  },
  {
    title: "Sztuka i rzemiosło",
  },
  {
    title: "Automotive",
  },
  {
    title: "Automobile",
  },
  {
    title: "Automobilowy",
  },
  {
    title: "Aviation and Aerospace",
  },
  {
    title: "Aviation et aérospatiale",
  },
  {
    title: "Lotnictwo i kosmonautyka",
  },
  {
    title: "Banking",
  },
  {
    title: "Banque",
  },
  {
    title: "Bankowość",
  },
  {
    title: "Capital Markets",
  },
  {
    title: "Finance",
  },
  {
    title: "Rynki kapitałowe",
  },
  {
    title: "Construction",
  },
  {
    title: "BTP",
  },
  {
    title: "Budowa",
  },
  {
    title: "Cosmetics",
  },
  {
    title: "Cométiques",
  },
  {
    title: "Kosmetyki",
  },
  {
    title: "E-Learning",
  },
  {
    title: "E-Learning",
  },
  {
    title: "E-learning",
  },
  {
    title: "Education",
  },
  {
    title: "Enseignement",
  },
  {
    title: "Edukacja",
  },
  {
    title: "Energy",
  },
  {
    title: "Energie",
  },
  {
    title: "Energia",
  },
  {
    title: "Entertainment",
  },
  {
    title: "Divertissement",
  },
  {
    title: "Zabawa/Widowisko",
  },
  {
    title: "Events Services",
  },
  {
    title: "Evénementiel",
  },
  {
    title: "Usługi imprezowe",
  },
  {
    title: "Fashion",
  },
  {
    title: "Mode",
  },
  {
    title: "Moda",
  },
  {
    title: "Food Industry",
  },
  {
    title: "Agroalimentaire",
  },
  {
    title: "Przemysł spożywczy",
  },
  {
    title: "Gambling and Casinos",
  },
  {
    title: "Jeux d'argent et casinos",
  },
  {
    title: "Hazard i kasyna",
  },
  {
    title: "Government Administration",
  },
  {
    title: "Administration gouvernementale",
  },
  {
    title: "Administracja rządowa",
  },
  {
    title: "Graphic Design",
  },
  {
    title: "Conception graphique",
  },
  {
    title: "Projekt graficzny",
  },
  {
    title: "Health Care",
  },
  {
    title: "Santé",
  },
  {
    title: "Opieka zdrowotna",
  },
  {
    title: "Human Resources",
  },
  {
    title: "Ressources humaines",
  },
  {
    title: "Zasoby ludzkie(HR)",
  },
  {
    title: "Industry",
  },
  {
    title: "Industrie ",
  },
  {
    title: "Przemysł",
  },
  {
    title: "Insurance",
  },
  {
    title: "Assurance",
  },
  {
    title: "Ubezpieczenie",
  },
  {
    title: "Internet",
  },
  {
    title: "Internet",
  },
  {
    title: "Internet",
  },
  {
    title: "Law Practice",
  },
  {
    title: "Droit",
  },
  {
    title: "Praktyka prawnicza",
  },
  {
    title: "Luxury",
  },
  {
    title: "Luxe",
  },
  {
    title: "Luksus",
  },
  {
    title: "Marketing and Advertising",
  },
  {
    title: "Marketing et publicité",
  },
  {
    title: "Marketing i reklama",
  },
  {
    title: "Media",
  },
  {
    title: "Médias",
  },
  {
    title: "Media",
  },
  {
    title: "Medical Practice",
  },
  {
    title: "Médecine",
  },
  {
    title: "Praktyka lekarska",
  },
  {
    title: "Motion Pictures and Film",
  },
  {
    title: "Cinéma",
  },
  {
    title: "Kinematografia i film",
  },
  {
    title: "Music",
  },
  {
    title: "Musique",
  },
  {
    title: "Muzyka",
  },
  {
    title: "Performing Arts",
  },
  {
    title: "Art",
  },
  {
    title: "Performing Arts",
  },
  {
    title: "Pharmaceuticals",
  },
  {
    title: "Industrie pharmaceutique",
  },
  {
    title: "Farmaceutyka",
  },
  {
    title: "Policics",
  },
  {
    title: "Politique",
  },
  {
    title: "Polityka",
  },
  {
    title: "Professional Training",
  },
  {
    title: "Formation",
  },
  {
    title: "Profesjonalne szkolenia i coaching",
  },
  {
    title: "Program Development",
  },
  {
    title: "Développement de programme",
  },
  {
    title: "Rozwój programu",
  },
  {
    title: "Public Relations and Communications",
  },
  {
    title: "Relations publiques et Relation de presse",
  },
  {
    title: "Relacje Publiczne i komunikacja",
  },
  {
    title: "Publishing",
  },
  {
    title: "Édition",
  },
  {
    title: "Wydawnictwo",
  },
  {
    title: "Real Estate",
  },
  {
    title: "Immobilier",
  },
  {
    title: "Nieruchomość",
  },
  {
    title: "Research",
  },
  {
    title: "Recherche",
  },
  {
    title: "Badania",
  },
  {
    title: "Restaurants",
  },
  {
    title: "Restauration",
  },
  {
    title: "Restauracje",
  },
  {
    title: "Retail",
  },
  {
    title: "Retail",
  },
  {
    title: "Sprzedaż",
  },
  {
    title: "Security",
  },
  {
    title: "Sécurité",
  },
  {
    title: "Ochrona",
  },
  {
    title: "Shipbuilding",
  },
  {
    title: "Construction navale",
  },
  {
    title: "Okrętownictwo",
  },
  {
    title: "Sports",
  },
  {
    title: "Sports",
  },
  {
    title: "Sporty",
  },
  {
    title: "Telecommunications",
  },
  {
    title: "Télécommunications",
  },
  {
    title: "Telekomunikacja",
  },
  {
    title: "Textiles",
  },
  {
    title: "Textiles",
  },
  {
    title: "Tekstylia",
  },
  {
    title: "Tourism",
  },
  {
    title: "Tourisme",
  },
  {
    title: "Turystyka",
  },
  {
    title: "Veterinary",
  },
  {
    title: "Vétérinaire",
  },
  {
    title: "Weterynaria",
  },
  {
    title: "Video Games",
  },
  {
    title: "Jeux vidéos",
  },
  {
    title: "Gry wideo",
  },
  {
    title: "Wholesale",
  },
  {
    title: "Vente en gros",
  },
  {
    title: "Hurt/Handel hurtowy",
  },
];
