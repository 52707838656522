import styled from "styled-components";
import React from "react";
import DropDownList from "../DropdownList/DropdownList";
import IconButton from "../IconButton/iconButton";
import AutoComplete from "../../ReusableComponents/DownshiftSelect/DownshiftSelect";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { spinnerStart, spinnerStop } from "../../../Redux/Actions/Actions";

const InputWraper = styled.div`
  height: 72px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  width: ${({ width }) => width}%;
`;
const FormLineWraper = styled.div`
  margin: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const Wraper = styled.div`
  width: 100%;
`;
const IconWraper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
`;

const LanguageForm = ({
  remove,
  handleChange,
  language,
  languageLevel,
  number,
  spinnerStart,
  spinnerStop,
  languages,
  setFieldValue,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const languagesOptions = [
    { value: "B1" },
    { value: "B2" },
    { value: "C1" },
    { value: "C2" },
  ];
  return (
    <Wraper>
      <FormLineWraper>
        <InputWraper width={40}>
          <AutoComplete
            name={`Languages[${number}].Language`}
            label={"Language"}
            variant={"outlined"}
            data={language}
            valueChange={handleChange}
            values={languages}
            setFieldValue={setFieldValue}
          />
        </InputWraper>
        <InputWraper width={30}>
          <DropDownList
            name={`Languages[${number}].Level`}
            style={{ width: "100%" }}
            labelName={"Level"}
            changeEvent={handleChange}
            menuItems={languagesOptions}
            value={languageLevel}
          />
        </InputWraper>
        <IconWraper>
          <IconButton
            Bin
            text={"Remove this language"}
            width={200}
            handleClick={() => {
              spinnerStart();
              remove(number);
              enqueueSnackbar("Removed Language", {
                variant: "success",
              });
              spinnerStop();
            }}
          />
        </IconWraper>
      </FormLineWraper>
    </Wraper>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    spinnerStart: () => dispatch(spinnerStart()),
    spinnerStop: () => dispatch(spinnerStop()),
  };
};

export default connect(null, mapDispatchToProps)(LanguageForm);
