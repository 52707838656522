import Spinner from "./SpinnerReducer";
import CvGenerator from "./CvGeneratorReducer";
import Identity from "./IdentityReducer";
import Stepper from "./StepperReducer";
import Career from "./CareerReducer";
import Experience from "./ExperienceReducer";
import Education from "./EducationReducer";
import Languages from "./LanguagesReducer";
import Skills from "./SkillsReducer";
import Hobbies from "./HobbieReducer";
import CvGenerated from "./GeneratedCvReducer";
import Errors from "./ErrorReducer";

import { combineReducers, createStore } from "redux";

const combine = combineReducers({
  CvGenerated,
  Spinner,
  CvGenerator,
  Identity,
  Stepper,
  Career,
  Experience,
  Education,
  Languages,
  Skills,
  Hobbies,
  Errors,
});

const store = createStore(
  combine,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
