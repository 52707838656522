import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
const StyledButton = styled.button`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  font-size: ${({ fontSize }) => fontSize}px;
  border-radius: 20px;
  background-color: rgb(46, 204, 113);
  border: 1px solid rgb(46, 204, 113);
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : 0)}px;

  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)}px;
  margin-left: ${({ left, marginLeft }) =>
    left ? "auto" : 0 || marginLeft ? marginLeft + "px" : 0};
  color: #fff;
  &:hover {
    cursor: pointer;
    color: #000;
  }
  &:focus {
    outline: 0;
  }
`;

const Button = ({
  text,
  handleClick,
  width,
  height,
  fontSize,
  left,
  marginRight,
  submit,
  disabled,
  type,
  marginBottom,
  marginLeft,
}) => {
  return (
    <StyledButton
      disabled={disabled}
      onClick={handleClick}
      height={height}
      width={width}
      fontSize={fontSize}
      left={left}
      marginLeft={marginLeft}
      marginRight={marginRight}
      type={submit && "submit"}
      marginBottom={marginBottom}
    >
      {text}
    </StyledButton>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fontSize: PropTypes.number.isRequired,
  left: PropTypes.bool,
  marginRight: PropTypes.number,
  marginLeft: PropTypes.number,
  marginBottom: PropTypes.number,
};

export default Button;
