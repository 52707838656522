import React, { useEffect } from "react";

//Redux
import { connect } from "react-redux";
import {
  updateUserInfo,
  setStep,
  setCareerBasicInfo,
} from "../../Redux/Actions/Actions";
//Services
import AuthService from "../../Services/AuthService";
import CareerService from "../../Services/CareerService";

//const
import StepHelper from "../../Const/StepType";

//components
import Navbar from "../Navbar/navBar";
import UploadPage from "../CvGenerator/UploadPage/uploadPage";
import Spinner from "../ReusableComponents/Spinner/spinner";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

const MainContent = ({
  updateUserInfo,
  Step,
  setStep,
  setCareerBasicInfo,
  isLoading,
  contactid,
  outputLanguage,
}) => {
  useEffect(() => {
    const link = window.location.href;
    const length = link.split("/").length;
    const step = link.split("/")[length - 1];
    const id = link.split("/")[length - 2];

    async function getData(id, step) {
      if (
        StepHelper.import !== step &&
        StepHelper.update !== step &&
        StepHelper.generate !== step
      ) {
        setStep(0);
      } else if (id.length !== 36) {
        setStep(0);
      } else if (StepHelper.import === step) {
        setStep(1);

        const { result, responseCode } = await CareerService.getCareerInfoBasic(
          id
        );
        if (responseCode >= 400) {
          setStep(0);
        } else {
          setCareerBasicInfo(result);
        }

        //save to redux store
      } else if (StepHelper.update === step) {
        setStep(10);
      } else if (StepHelper.generate === step) {
        setStep(7);
      }
    }

    async function getBuisnessData() {
      if (contactid) {
        const result = await AuthService.getContactInformations(contactid);

        updateUserInfo(result);
      }
    }
    if (outputLanguage.length === 0) {
      getBuisnessData();
      getData(id, step);
    }
  }, [setCareerBasicInfo, setStep, updateUserInfo, contactid, outputLanguage]);

  return (
    <>
      {isLoading && <Spinner />}
      {Step === 1 && <Navbar />}
      {Step === 1 && <UploadPage />}
      {Step === 0 && <NotFoundPage />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Step: state.Stepper.Step,
    isLoading: state.Spinner.isSpinnerOn,
    contactid: state.Career.contactId,
    outputLanguage: state.CvGenerator.cvOutputLanguage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserInfo: (data) => dispatch(updateUserInfo(data)),
    setStep: (data) => dispatch(setStep(data)),
    setCareerBasicInfo: (data) => dispatch(setCareerBasicInfo(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContent);
