import {
  GENERATED_CV_SET_PDF_LINK,
  GENERATED_CV_SET_WORD_LINK,
  GENERATED_CV_SET_FILE_NAME,
} from "../Actions/Actions";

const initialState = {
  wordUrl: "",
  pdfUrl: "",
  fileName: "",
};
const GeneratedCv = (state = initialState, action) => {
  switch (action.type) {
    case GENERATED_CV_SET_PDF_LINK: {
      return {
        ...state,
        pdfUrl: action.payload.data,
      };
    }
    case GENERATED_CV_SET_WORD_LINK: {
      return {
        ...state,
        wordUrl: action.payload.data,
      };
    }
    case GENERATED_CV_SET_FILE_NAME: {
      return {
        ...state,
        fileName: action.payload.data,
      };
    }
    default:
      return state;
  }
};

export default GeneratedCv;
