export const LANGUAGES_SET_SCRAPED_DATA = "LANGUAGES_SET_SCRAPED_DATA";
export const LANGUAGES_SET_FORM_DATA = "LANGUAGES_SET_FORM_DATA";

export const setLanguagesScrapedData = (data) => ({
  type: LANGUAGES_SET_SCRAPED_DATA,
  payload: { data },
});

export const setLanguagesFormData = (data) => ({
  type: LANGUAGES_SET_FORM_DATA,
  payload: { data },
});
