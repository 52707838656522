import * as Yup from "yup";

export const filterExperience = (experience) => {
  const experienceList = experience.value.map((item) => {
    let from = "";
    if (item.btk_from) {
      from = item.btk_from.split("T")[0];
      from = from.split("-");
      from = new Date(from[0], from[1] - 1, from[2]);
    }

    let to = {};

    if (item.btk_currentjob) {
      to = new Date();
    } else {
      if (item.btk_to) {
        to = item.btk_to.split("T")[0];
        to = to.split("-");
        to = new Date(to[0], to[1] - 1, to[2]);
      } else {
        to = "";
      }
    }

    return {
      id: item.btk_experienceid,
      currentJob: item.btk_currentjob,
      to,
      from,
      company: item.btk_company ? item.btk_company : "",
      client: item.btk_client ? item.btk_client : "",
      city: item.btk_city ? item.btk_city : "",
      country: item.btk_country ? item.btk_country.btk_name : "",
      jobTitle: item.btk_name ? item.btk_name : "",
      jobDescription: item.btk_description ? item.btk_description : "",
      technical: item.btk_technicalenvironment
        ? item.btk_technicalenvironment
        : "",
    };
  });

  const sortedList = experienceList.sort((a, b) => {
    return new Date(a.from) - new Date(b.from);
  });

  return sortedList.reverse();
};

export const experienceSchema = Yup.object().shape({
  titlecv: Yup.string().required("Required"),
  yearOfExperience: Yup.number().min(1).required("Required"),
  Experiences: Yup.array().of(
    Yup.object({
      id: Yup.string(),
      from: Yup.date().required("Required"),
      to: Yup.date()
        .nullable()
        .when("currentJob", {
          is: false,
          then: Yup.date().min(Yup.ref('from'),"The start date can't be after the end date").required("Required"),
        }),
      company: Yup.string(),
      client: Yup.string(),
      city: Yup.string(),
      currentJob: Yup.boolean(),
      country: Yup.string(),
      technical: Yup.string(),
      jobDescription: Yup.object({
        blocks: Yup.array()
          .min(1)
          .of(
            Yup.object({
              text: Yup.string(),
            })
          )
          .test("is more values", (blocks) => {
            let firstElement;

            if (blocks) {
              firstElement = blocks[0].text.length >= 1;
            } else {
              firstElement = false;
            }
            return firstElement;
          }),
      }),
      jobTitle: Yup.string().required("Required"),
    })
  ),
});
