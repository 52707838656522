import { HOBBIES_VALUES_CHANGE } from "../Actions/Actions";

const initialState = {
  hobbies: "",
};

const Hobbies = (state = initialState, action) => {
  switch (action.type) {
    case HOBBIES_VALUES_CHANGE: {
      return {
        ...state,
        hobbies: action.payload.data,
      };
    }
    default:
      return state;
  }
};

export default Hobbies;
