export const GENERATED_CV_SET_WORD_LINK = "GENERATED_CV_SET_WORD_LINK";
export const GENERATED_CV_SET_PDF_LINK = "GENERATED_CV_SET_PDF_LINK";
export const GENERATED_CV_SET_FILE_NAME = "GENERATED_CV_SET_FILE_NAME";

export const setWordLink = (data) => ({
  type: GENERATED_CV_SET_WORD_LINK,
  payload: { data },
});

export const setPdfLink = (data) => ({
  type: GENERATED_CV_SET_PDF_LINK,
  payload: { data },
});

export const setFileName = (data) => ({
  type: GENERATED_CV_SET_FILE_NAME,
  payload: { data },
});
