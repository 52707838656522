import styled from "styled-components";
import { BsTrash } from "react-icons/bs";

export const TrashIcon = styled(BsTrash)`
  font-size: 18px;
  margin-right: 8px;
  cursor: pointer;
`;

export const Wraper = styled.div`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`;
export const HeaderText = styled.h1`
  text-align: center;
  color: #2ecc71;
  margin-top: 10vh;
  font-size: 21px;
`;
export const UploadBox = styled.div`
  display: flex;
  margin-top: 50px;
  margin-left: 20%;

  height: 50px;
`;
export const BoxText = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 18px;
  margin-right: 10px;
  width: 50%;
`;
export const ButtonWraper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
`;
export const DataBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
`;
export const DividerBox = styled.div`
  height: 56px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;
export const ErrorMessage = styled.p`
  font-size: 12px;
  margin-top: 5px;
  color: red;
`;
export const FileInputWraper = styled.div`
  width: 190px;
  display: flex;
  flex-direction: column;
`;
// Select language components
export const Splitter = styled.div`
  height: 2px;
  border-bottom: 1px solid black;
  width: 90%;
`;
export const SplitterText = styled.div`
  font-size: 18px;
  width: 70%;
  text-align: left;
  margin-top: 70px;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-right: auto;
`;
export const Text = styled.div`
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  color: ${({ error }) => (error ? "red" : "black")};
`;

export const RadioButtons = styled.div`
  display: flex;
  width: 60%;
  align-items: flex-start;
`;

export const RadioWrapper = styled.div`
  width: 33%;
  display: flex;
  align-items: flex-start;
`;
