import {
  EXPERIENCE_REMOVE_EXPERIENCE,
  EXPERIENCE_ADD_NEW_EXPERIENCE,
  EXPERIENCE_UPDATE_EXPERIENCE,
  EXPERIENCE_TITLE_OF_CV_CHANGE,
  EXPERIENCE_YEARS_OF_EXPERIENCE_CHANGE,
} from "../Actions/Actions";

const initialState = {
  titleOfCV: "",
  yearsOfExperience: 0,
  Experiences: [
    {
      id: 0,
      from: null,
      to: null,
      company: "",
      client: "",
      city: "",
      country: "",
      jobTitle: "",
      jobDescription: "",
      technical: "",
      currentJob: false,
    },
  ],
};

const experienceReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXPERIENCE_UPDATE_EXPERIENCE: {
      return {
        ...state,
        Experiences: action.payload.data,
      };
    }
    case EXPERIENCE_REMOVE_EXPERIENCE: {
      return {
        ...state,
        Experiences: state.Experiences,
      };
    }
    case EXPERIENCE_ADD_NEW_EXPERIENCE: {
      state.Experiences.push({
        id: 0,
        from: null,
        to: null,
        company: "",
        client: "",
        city: "",
        country: "",
        jobTitle: "",
        jobDescription: "",
        technical: "",
        currentJob: null,
      });
      return {
        ...state,
        Experiences: state.Experiences,
      };
    }
    case EXPERIENCE_TITLE_OF_CV_CHANGE: {
      return {
        ...state,
        titleOfCV: action.payload.data,
      };
    }
    case EXPERIENCE_YEARS_OF_EXPERIENCE_CHANGE: {
      return {
        ...state,
        yearsOfExperience: action.payload.data,
      };
    }
    default:
      return state;
  }
};

export default experienceReducer;
