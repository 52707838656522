import AuthController from '../AuthProvider/AuthController';

class ExperienceService {
  //
  // Get Experiences
  //
  async get(id, language) {
    const token = await AuthController.getToken();
    let result = await fetch(
      process.env.REACT_APP_API_URL + "/api/experience?careerid=" + id + "&language=" + language,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        method: "POST",
      }
    );
    let responseCode = result.status;
    if (responseCode >= 400) {
      result = null;
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }
  //
  // Send model
  //
  async send(model, language) {
    let token = await AuthController.getToken();

    let result = await fetch(
      process.env.REACT_APP_API_URL + "/api/career/experience?language=" + language,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(model),
      }
    );
    let responseCode = result.status;
    if (responseCode >= 400) {
      result = null;
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }
  //
  // Remove experience
  //
  async remove(id) {
    const token = await AuthController.getToken();
    let result = await fetch(
      process.env.REACT_APP_API_URL + "/api/career/experience/" + id,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        method: "DELETE",
      }
    );
    let responseCode = result.status;
    if (responseCode >= 400) {
      result = null;
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }
  //
  // Update
  //
  async update(model, id, language) {
    const token = await AuthController.getToken();
    let result = await fetch(
      process.env.REACT_APP_API_URL + "/api/career/experience/" + id + "?language=" + language,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
          "Content-Type": "application/json",
        },
        method: "PATCH",
        body: JSON.stringify(model),
      }
    );

    let responseCode = result.status;
    if (responseCode >= 400) {
      result = null;
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }

  //
  // Scrap data from CV
  //
  async scrap(data, code, language) {
    const token = await AuthController.getToken();

    let result = await fetch(
      process.env.REACT_APP_API_URL + "/api/experience?code=" + code + "&language=" + language,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        method: "POST",
        body: data,
      }
    );

    let responseCode = result.status;
    if (responseCode >= 400) {
      result = null;
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }
}


export default new ExperienceService();
