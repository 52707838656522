export const ERROR_SET = "ERROR_SET";
export const ERROR_REMOVE = "ERROR_REMOVE";

export const setErrors = (data) => ({
  type: ERROR_SET,
  payload: { data },
});

export const removeErrors = () => ({
  type: ERROR_REMOVE,
});
