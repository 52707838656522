import styled from "styled-components";
import React from "react";
import { connect } from "react-redux";

import EducationService from "../../../Services/EducationService";

//components
import Input from "../TextInput/textInput";
import IconButton from "../IconButton/iconButton";
import {
  setEducationArray,
  spinnerStart,
  spinnerStop,
} from "../../../Redux/Actions/Actions";
import { useSnackbar } from "notistack";

//Styled input
const InputWraper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  width: ${({ width }) => width}%;
`;
const FormLineWraper = styled.div`
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;
const Wraper = styled.div`
  width: 100%;
`;
const IconWraper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const ExperienceForm = ({
  edu,
  number,
  remove,
  error,
  touched,
  handleChange,
  handleBlur,
  spinnerStart,
  spinnerStop,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const removeEducation = async () => {
    spinnerStart();
    if (edu.eduId) {
      if (edu.eduId.toString().length > 10) {
        //remove education
        const isSuccess = await EducationService.remove(edu.eduId);
        if (isSuccess) {
          enqueueSnackbar("Removed Education - from CRM", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Removed Education from CRM - error", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("Removed Education", {
          variant: "success",
        });
      }
    } else {
      enqueueSnackbar("Removed Education", {
        variant: "success",
      });
    }
    spinnerStop();
  };

  return (
    <Wraper>
      <FormLineWraper>
        <InputWraper width={20}>
          <Input
            name={`Education[${number}].eduYear`}
            handleBlur={handleBlur}
            error={error && touched && !!error.eduYear && touched.eduYear}
            label={"Year"}
            variant={"outlined"}
            valueChange={handleChange}
            value={edu.eduYear}
          />
        </InputWraper>
        <InputWraper width={80}>
          <Input
            name={`Education[${number}.eduDiploma]`}
            handleBlur={handleBlur}
            error={error && touched && !!error.eduDiploma && touched.eduDiploma}
            label={"Diploma"}
            variant={"outlined"}
            valueChange={handleChange}
            value={edu.eduDiploma}
            inputProps={{maxLength: 200}}
          />
        </InputWraper>
      </FormLineWraper>

      <IconWraper>
        <IconButton
          Bin
          text={"Remove this diploma"}
          width={200}
          handleClick={() => {
            removeEducation();
            remove(number);
          }}
        />
      </IconWraper>
    </Wraper>
  );
};

const mapStateToProps = (state) => {
  return {
    Education: state.Education.Education,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEducationArray: (data) => dispatch(setEducationArray(data)),
    spinnerStart: () => dispatch(spinnerStart()),
    spinnerStop: () => dispatch(spinnerStop()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExperienceForm);
