import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { setSkillsHelper } from "../Skills/helperFunctions";
//Actions
import {
  spinnerStart,
  spinnerStop,
  moveToNextStep,
  setWordLink,
  setPdfLink,
  setFileName,
  setErrors,
  removeErrors,
  setLanguagesFormData,
  setOutputLanguage,
  setSkills,
  setStep,
} from "../../../Redux/Actions/Actions";
//components
import Stepper from "../../ReusableComponents/Stepper/stepper";
import Button from "../../ReusableComponents/Button/button";
import DropDownList from "../../ReusableComponents/DropdownList/DropdownList";
import RadioButton from "../../ReusableComponents/RadioButton/radioButton";
import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';
import {
  Splitter,
  Spliter,
  ButtonWraper,
  SplitterText,
  FormFields,
  Form,
  ErrorText,
  DropdownWraper,
  Wraper,
  RadioButtons,
  RadioWrapper,
  Text,
} from "./StyledComponents";

//Services
import CvService from "../../../Services/CvService";
import PowerAutomateService from "../../../Services/PowerAutomateService";
import CareerService from "../../../Services/CareerService";

import { useSnackbar } from "notistack";

const GenerateCv = ({
  spinnerStart,
  spinnerStop,
  moveToNextStep,
  languages,
  careerId,
  contactId,
  businesssector,
  outputLanguage,
  firstName,
  setWordLink,
  setPdfLink,
  lastName,
  setFileName,
  setErrors,
  errors,
  removeErrors,
  stepType,
  isLanguagesScraped,
  setLanguagesFormData,
  setOutputLanguage,
  isSkillsScraped,
  setSkills,
  setStep,
}) => {
  const [cvOutputLanguage, setCvOutputLanguage] = useState(null);
  const [isGenerateStep, setIsGenerateStep] = useState(false);
  const [localLanguages, setLocalLanguages] = useState(null);
  const [localBussinessSector, setBussinessSector] = useState(null);
  const [basicCareer, setBasicCareer] = useState(null);
  const [showErrors, setShowErrors] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [messageToDisplay, setMessageToDisplay] = useState(null);
  const [goToStep, setGoToStep] = useState(null);
  const [fromStep, setFromStep] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  //Set errors
  useEffect(() => {
    setLocalErrors(errors);
  }, [errors]);

  //get languages - update step
  useEffect(() => {
    async function getLanguages() {
      //get languages
      let languages = await CareerService.getLanguages(
        careerId,
        outputLanguage
      );

      if (languages.responseCode >= 400) {
        //manage error
        enqueueSnackbar("Get Languages - error", {
          variant: "error",
        });
      } else {
        languages = languages.result;

        if (languages) {
          languages = languages.map((item) => {
            return {
              Language: item.split(" ")[0],
              Level: item.split(" ")[1],
            };
          });
          setLanguagesFormData(languages);
        }
      }
    }

    if (stepType === "updateStep" && isLanguagesScraped === false) {
      getLanguages();
    }
  }, [
    enqueueSnackbar,
    careerId,
    isLanguagesScraped,
    outputLanguage,
    stepType,
    setLanguagesFormData,
  ]);

  // get business sector -update step
  useEffect(() => {
    document.title = "Generate Bertek CV";
    async function getSkills() {
      spinnerStart();
      let isNotEmptyXP = false;
      //careerInfo
      const careerInfo = await CareerService.getCareerInfoBasic(careerId);
      if (careerInfo.responseCode >= 400) {
        //error message
        enqueueSnackbar("Get Career info - error", {
          variant: "error",
        });
        setStep(0);
      } else {
          if(careerInfo.result.cvtitle && careerInfo.result.yearsOfExperience){
            isNotEmptyXP = true;
            const firstName = careerInfo.result.name.firstname;
            const lastName = careerInfo.result.name.lastname;
            const contactId = careerInfo.result.name.contactid;
            const career = {
              firstName,
              lastName,
              contactId,
              careerId: careerId,
            };
            setBasicCareer(career);
          } else {
            setMessageToDisplay("The career of your candidate is empty, we can not generate a CV. Do you want to import a CV to create the Bertek CV ?");
            setGoToStep("update");
            setFromStep("update");
          }
        }
      //skills
      const skills = await CareerService.getSkills(careerId, outputLanguage);

      if (skills.responseCode >= 400) {
        enqueueSnackbar("Get Skills - error", {
          variant: "error",
        });
      } else {
        if (skills.result) {
          const skill = skills.result.value[0];
          const init = setSkillsHelper(skill);
          setSkills({ ...init });
        } else if (!skills.result && isNotEmptyXP) {
          setMessageToDisplay("The career of your canditate has very little datas. Before generating the Bertek CV, you have to make some updates.");
          setGoToStep("update");
          setFromStep("generate");
        }
      }
      
      spinnerStop();
    }

    if (isSkillsScraped === false && stepType === "updateStep") {
      getSkills();
    }
  }, [
    setSkills,
    isSkillsScraped,
    spinnerStart,
    spinnerStop,
    outputLanguage,
    careerId,
    enqueueSnackbar,
    stepType,
    setStep,
  ]);

  //generate
  useEffect(() => {
    //Get careerId and step type

    const link = window.location.href;
    const length = link.split("/").length;
    const step = link.split("/")[length - 1];
    const id = link.split("/")[length - 2];

    async function getData(id) {
      if (step === "generate") {
        spinnerStart();
        setIsGenerateStep(true);
        let isNotEmptyXP = false;
        //career
        const careerInfo = await CareerService.getCareerInfoBasic(id);
        if (careerInfo.responseCode >= 400) {
          //error message
          enqueueSnackbar("Get Career info - error", {
            variant: "error",
          });
          setStep(0);
        } else {
          if(careerInfo.result.cvtitle && careerInfo.result.yearsOfExperience){
            isNotEmptyXP = true;
            const firstName = careerInfo.result.name.firstname;
            const lastName = careerInfo.result.name.lastname;
            const contactId = careerInfo.result.name.contactid;
            const career = {
              firstName,
              lastName,
              contactId,
              careerId: id,
            };
            setBasicCareer(career);
          } else {
            setMessageToDisplay("The career of your candidate is empty, we can not generate a CV. Do you want to import a CV to create the Bertek CV ?");
            setGoToStep("import");
            setFromStep("generate");
          }
          
        }

        //language
        const languages = await CareerService.getLanguages(
          id,
          cvOutputLanguage
        );
        if (languages.responseCode >= 400) {
          //error message
          enqueueSnackbar("Get Languages - error", {
            variant: "error",
          });
        } else {
          setLocalLanguages(languages.result);
        }

        //bussiness sector
        const skills = await CareerService.getSkills(id, cvOutputLanguage);
        if (skills.responseCode >= 400) {
          //error message
          enqueueSnackbar("Get Skills - error", {
            variant: "error",
          });
        } else {
          // set bussiness sector
          if (skills.result) {
            setBussinessSector(skills.result.value[0].btk_businesssectors);
          } else if (!skills.result && isNotEmptyXP) {
            setMessageToDisplay("The career of your canditate has very little datas. Before generating the Bertek CV, you have to make some updates.");
            setGoToStep("update");
            setFromStep("generate");
            // setBussinessSector("");
          }
        }
        spinnerStop();
      }
    }
    if (cvOutputLanguage !== null) {
      getData(id);
    }
  }, [
    cvOutputLanguage,
    spinnerStop,
    spinnerStart,
    setIsGenerateStep,
    setBussinessSector,
    setLocalLanguages,
    setBasicCareer,
    enqueueSnackbar,
    setStep,
  ]);

  //Move to next step
  const handleNextClick = async () => {
    removeErrors();
    if(goToStep && fromStep && messageToDisplay) {
      setDialogOpen(true);
    } else {

      let isValid = true;

      if (fullContact === null) {
        setContactError(true);
        isValid = false;
      }

      if (cvOutputLanguage === null && stepType === "generate") {
        setRadioOutputLanguageError(true);
        isValid = false;
      }

      if (isValid) {
        spinnerStart();

        const languagesToFile = [];

        if (!isGenerateStep) {
          //create request
          if (languages) {
            for (let i = 0; i < languages.length; i++) {
              if (languages[i] !== null) {
                languagesToFile.push({
                  NAME: languages[i].Language,
                  LEVEL: languages[i].Level,
                });
              }
            }
          }
        } else {
          if (localLanguages) {
            for (let i = 0; i < localLanguages.length; i++) {
              const lang = localLanguages[i].split(" ");
              languagesToFile.push({
                NAME: lang[0],
                LEVEL: lang[1],
              });
            }
          }
        }

        const currentDate = new Date();
        const timestamp = currentDate.getTime().toString().substr(-3);
        let cvName = "";
        if (isGenerateStep) {
          cvName = `${basicCareer.firstName}_${basicCareer.lastName}_${
            currentDate.getMonth() + 1
          }_${currentDate.getFullYear().toString().substr(-2)}_${timestamp}`;
        } else {
          cvName = `${firstName}_${lastName}_${
            currentDate.getMonth() + 1
          }_${currentDate.getFullYear().toString().substr(-2)}_${timestamp}`;
        }

        //Generate FILE Body
        setFileName(cvName);

        let bussiness = "";
        let career = "";

        let contact = "";

        if (isGenerateStep) {
          bussiness = localBussinessSector;
          career = basicCareer.careerId;

          contact = fullContact.systemuserid;
        } else {
          bussiness = businesssector;
          career = careerId;

          contact = fullContact.systemuserid;
        }

        if (Array.isArray(bussiness)) {
          bussiness = bussiness.map((c) => c.title);

          bussiness = bussiness.join(", ");
        }
        // TODO Change the call of token here?
        const generateFileModel = {
          CvLanguageOutput: outputLanguage,
          CvLanguage: outputLanguage,
          businesssector: bussiness,
          userid: contact,

          CvName: cvName,
          careerid: career,
          languages: languagesToFile,
          Token: sessionStorage.getItem("msal.idtoken"),
        };

        const {
          result,
          responseCode,
        } = await PowerAutomateService.generateBertekFiles(generateFileModel);

        if (responseCode >= 400) {
          // const errorArray = [];
          // errorArray.push("Convert into Bertek CV - Error");
          //setErrors(errorArray);
        } else {
          const wordLink = result.WordLink;
          const pdfLink = result.PdfLink;

          setWordLink(wordLink);
          setPdfLink(pdfLink);
        }

        spinnerStop();
        moveToNextStep(9);
      }
    }
  };

  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState("");
  const [fullContact, setFullContact] = useState(null);
  const [contactError, setContactError] = useState(false);
  const [fullContactList, setFullContactList] = useState([]);
  const [localErrors, setLocalErrors] = useState([]);

  const [radioOutputLanguageError, setRadioOutputLanguageError] = useState(
    false
  );

  const radioOutputLanguageChange = (e) => {
    setOutputLanguage(e.target.value);
    setCvOutputLanguage(e.target.value);
    setRadioOutputLanguageError(false);
  };

  //Fetch Data
  useEffect(() => {
    const getContacts = async () => {
      spinnerStart();

      const { result, responseCode } = await CvService.getCommercialContacts();
      if (responseCode >= 400) {
        enqueueSnackbar("Get Comercial Contacts - error", {
          variant: "error",
        });
      }

      result.sort((a, b) => a.fullname.localeCompare(b.fullname));

      setFullContactList(result);
      let contacts = [];
      for (let i = 0; i < result.length; i++) {
        contacts.push({ value: "" });
        contacts[i].value = result[i].fullname;
      }
      setContacts(contacts);

      spinnerStop();
    };
    getContacts();
  }, [setContacts, spinnerStop, spinnerStart, setLocalErrors, enqueueSnackbar]);

  //Errors
  useEffect(() => {
    if (localErrors) {
      for (let i = 0; i < localErrors.length; i++) {
        enqueueSnackbar(localErrors[i], {
          variant: "error",
        });
      }
    }
    setShowErrors(false);
  }, [localErrors, enqueueSnackbar, setShowErrors, showErrors]);

  //Pick commercial contact
  const contactChange = (e) => {
    setSelectedContact(e.target.value);
    const fullName = e.target.value;

    let fullContact;
    if (fullName.length > 0) {
      fullContact = fullContactList.find((c) => c.fullname === fullName);
      if (fullContact === undefined) {
        setFullContact(null);
      } else {
        setFullContact(fullContact);
        setContactError(false);
      }
    } else {
      setFullContact(null);
    }
  };

  return (
    <Wraper>
      <Form>
        {stepType === "importStep" && <Stepper StepNumber={5} hobbies />}
        {stepType === "updateStep" && <Stepper StepNumber={5} hobbies />}
        <FormFields>
          <SplitterText>
            Commercial contact: add the name of the commercial contact directly
            on the CV
          </SplitterText>
          <Spliter />
          <DropdownWraper>
            <DropDownList
              labelName={"Select commercial contact"}
              value={selectedContact}
              menuItems={contacts}
              changeEvent={contactChange}
            />
          </DropdownWraper>
          {contactError && <ErrorText>Select commercial contact</ErrorText>}
        </FormFields>
        {stepType !== "updateStep" && stepType !== "importStep" && (
          <>
            <SplitterText style={{ marginTop: "40px" }}>
              Tell us the language of the Bertek CV you are going to create
            </SplitterText>
            <Splitter />
            <RadioButtons>
              <RadioWrapper>
                <RadioButton
                  handleChange={radioOutputLanguageChange}
                  value={"English"}
                  checked={cvOutputLanguage === "English" ? true : false}
                />{" "}
                <Text error={radioOutputLanguageError}>English</Text>
              </RadioWrapper>
              <RadioWrapper>
                <RadioButton
                  handleChange={radioOutputLanguageChange}
                  value={"French"}
                  checked={cvOutputLanguage === "French" ? true : false}
                />{" "}
                <Text error={radioOutputLanguageError}>French</Text>
              </RadioWrapper>
              <RadioWrapper>
                <RadioButton
                  handleChange={radioOutputLanguageChange}
                  value={"Polish"}
                  checked={cvOutputLanguage === "Polish" ? true : false}
                />{" "}
                <Text error={radioOutputLanguageError}>Polish </Text>
              </RadioWrapper>
            </RadioButtons>
          </>
        )}
        <DialogBox isOpen={dialogOpen} messageToDisplay={messageToDisplay} fromStep={fromStep} goToStep={goToStep} setDialog={setDialogOpen}/>
        <ButtonWraper>
          <Button
            height={30}
            width={220}
            text={"Generate Bertek CV"}
            handleClick={handleNextClick}
            left
            fontSize={16}
          />
        </ButtonWraper>
      </Form>
    </Wraper>
  );
};

const mapStateToProps = (state) => {
  return {
    stepType: state.Stepper.stepType,
    errors: state.Errors.errors,
    firstName: state.Career.firstName,
    lastName: state.Career.lastName,
    outputLanguage: state.CvGenerator.cvOutputLanguage,
    languages: state.Languages.Languages,
    careerId: state.Career.careerId,
    contactId: state.Career.contactId,
    businesssector: state.Skills.businessSector,
    isLanguagesScraped: state.Stepper.isLanguagesScraped,
    isSkillsScraped: state.Stepper.isSkillsScraped,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStep: (data) => dispatch(setStep(data)),
    setOutputLanguage: (data) => dispatch(setOutputLanguage(data)),
    removeErrors: () => dispatch(removeErrors()),
    setErrors: (data) => dispatch(setErrors(data)),
    setFileName: (data) => dispatch(setFileName(data)),
    setWordLink: (data) => dispatch(setWordLink(data)),
    setPdfLink: (data) => dispatch(setPdfLink(data)),
    moveToNextStep: (data) => dispatch(moveToNextStep(data)),
    spinnerStart: () => dispatch(spinnerStart()),
    spinnerStop: () => dispatch(spinnerStop()),
    setLanguagesFormData: (data) => dispatch(setLanguagesFormData(data)),
    setSkills: (data) => dispatch(setSkills(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateCv);
