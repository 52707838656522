import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { moveToNextStep } from "../../Redux/Actions/Actions";
import {
  DocumentWraper,
  HeaderText,
  Wraper,
  FileWraper,
  FileText,
  IconWraperPdf,
  IconWraperWord,
  StyledWord,
  StyledPdf,
  HighlightedHeaderText,
  A,
} from "./StyledComponents";

const CvDone = ({ fileName, pdfUrl, wordUrl, moveToNextStep }) => {
  const [canMoveBack, setCanMoveBack] = useState(false);
  const [stepName, setStepName] = useState("");

  useEffect(() => {
    const link = window.location.href;
    const length = link.split("/").length;
    const step = link.split("/")[length - 1];
    setStepName(step);
    if (step === "update" || step === "import") {
      setCanMoveBack(true);
    }
  }, [setCanMoveBack]);

  const MoveBack = () => {
    moveToNextStep(7);
  };
  const moveToUpdate = () => {
    if (stepName === "generate") {
      const link = window.location.href;
      const newLink = link.replace("generate", "update");
      window.location.href = newLink;
    } else {
      moveToNextStep(7);
    }
  };
  return (
    <Wraper>
      {pdfUrl.length > 6 ? (
        <>
          <HeaderText>
            Well done ! CV has been transformed correctly in Bertek CV. You can
            find it anytime in SharePoint document library.
          </HeaderText>
          {canMoveBack && (
            <HighlightedHeaderText onClick={moveToUpdate}>
              Something is not okay ? You can change it quickly ! <br />
              Click here to go back and apply changes.
            </HighlightedHeaderText>
          )}
        </>
      ) : (
        <HighlightedHeaderText onClick={MoveBack}>
          Something goes wrong. Try to generate Bertek CV again. <br />
          Click here to go back
        </HighlightedHeaderText>
      )}
      {pdfUrl.length > 6 && (
        <DocumentWraper>
          <FileWraper>
            <IconWraperWord>
              <StyledWord />
            </IconWraperWord>
            <FileText>
              <A target="_blank" href={wordUrl}>
                {" "}
                {fileName}.docx
              </A>
            </FileText>
          </FileWraper>
          <FileWraper>
            <IconWraperPdf>
              <StyledPdf />
            </IconWraperPdf>
            <FileText>
              {" "}
              <A target="_blank" href={pdfUrl}>
                {" "}
                {fileName}.pdf
              </A>
            </FileText>
          </FileWraper>
        </DocumentWraper>
      )}
    </Wraper>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.Errors.errors,
    fileName: state.CvGenerated.fileName,
    pdfUrl: state.CvGenerated.pdfUrl,
    wordUrl: state.CvGenerated.wordUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveToNextStep: (data) => dispatch(moveToNextStep(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CvDone);
