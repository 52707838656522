import { SKILLS_SET_SKILLS } from "../Actions/Actions";

const initialState = {
  businessLine: "",
  crossDisciplinarySkills: "",
  db: "",
  frameworks: "",
  languages: "",
  methodology: "",
  softSkills: "",
  tools: "",
  businessSector: "",
};

const Skills = (state = initialState, action) => {
  switch (action.type) {
    case SKILLS_SET_SKILLS: {
      return {
        ...state,
        businessLine: action.payload.data.businessLine,
        crossDisciplinarySkills: action.payload.data.crossDisciplinarySkills,
        db: action.payload.data.db,
        frameworks: action.payload.data.frameworks,
        languages: action.payload.data.languages,
        methodology: action.payload.data.methodology,
        softSkills: action.payload.data.softSkills,
        tools: action.payload.data.tools,
        businessSector: action.payload.data.businessSector,
      };
    }

    default:
      return state;
  }
};

export default Skills;
