export const GENERATOR_CV_CHANGE = "GENERATOR_CV_CHANGE";
export const GENERATOR_LINKEDIN_CHANGE = "GENERATOR_LINKEDIN_CHANGE";
export const GENERATOR_LANGUAGE_CHANGE = "GENERATOR_LANGUAGE_CHANGE";
export const GENERATOR_CV_PDF_CHANGE = "GENERATOR_CV_PDF_CHANGE";
export const GENERATOR_SET_SCRAPED_CV_DATA = "GENERATOR_SET_SCRAPED_CV_DATA";
export const GENERATOR_CV_OUTPUT_LANGUAGE_CHANGE = "GENERATOR_CV_OUTPUT_LANGUAGE_CHANGE";
export const GENERATOR_CV_TIMESTAMP = "GENERATOR_CV_TIMESTAMP";

export const cvChange = (data) => ({
  type: GENERATOR_CV_CHANGE,
  payload: { data },
});

export const linkedInChange = (data) => ({
  type: GENERATOR_LINKEDIN_CHANGE,
  payload: { data },
});

export const languageChange = (data) => ({
  type: GENERATOR_LANGUAGE_CHANGE,
  payload: { data },
});

export const cvPdfChange = (data) => ({
  type: GENERATOR_CV_PDF_CHANGE,
  payload: { data },
});

export const setScrapedData = (data) => ({
  type: GENERATOR_SET_SCRAPED_CV_DATA,
  payload: { data },
});

export const setOutputLanguage = (data) => ({
  type: GENERATOR_CV_OUTPUT_LANGUAGE_CHANGE,
  payload: { data },
});

export const setTimeStamp = (data) => ({
  type: GENERATOR_CV_TIMESTAMP,
  payload: { data },
});
