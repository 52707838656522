import styled from "styled-components";
import { BsThreeDotsVertical } from "react-icons/bs";
export const Dots = styled(BsThreeDotsVertical)`
  color: black;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Wraper = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 80vh;
  min-height: 600px;
  margin-top: 50px;
`;
export const StyleForm = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const FormFields = styled.div`
  height: 75%;
  width: 100%;
`;

export const Cv = styled.div`
  width: 45%;
`;
export const ButtonWraper = styled.div`
  height: 5%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
`;
