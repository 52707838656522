import styled from "styled-components";
import { AiOutlineFileWord, AiOutlineFilePdf } from "react-icons/ai";

export const A = styled.a`
  text-decoration: none;
  color: black;
`;
export const StyledWord = styled(AiOutlineFileWord)`
  font-size: 44px;
`;
export const StyledPdf = styled(AiOutlineFilePdf)`
  font-size: 44px;
`;
export const IconWraperWord = styled.div`
  width: 80px;
`;
export const IconWraperPdf = styled.div`
  width: 80px;
`;
export const FileText = styled.p`
  margin-top: 10px;
  height: 25px;

  border-bottom: 1px solid black;
  font-size: 18px;
  cursor: pointer;
`;
export const FileWraper = styled.div`
  width: 420px;
  display: flex;
  flex-wrap: nowrap;
`;
export const Wraper = styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
`;
export const HeaderText = styled.h1`
  color: #2ecc71;
  font-size: 22px;
  margin-top: 75px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

export const HighlightedHeaderText = styled.h1`
  color: #2ecc71;
  font-size: 22px;
  margin-top: 75px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;
export const DocumentWraper = styled.div`
  margin-top: 90px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;
