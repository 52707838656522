import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Text = styled.div`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  text-align: center;
`;

const StepHeader = ({ children }) => {
  return <Text>{children}</Text>;
};

StepHeader.propTypes = {
  children: PropTypes.string.isRequired,
};

export default StepHeader;
