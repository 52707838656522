import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Document, Page, pdfjs } from "react-pdf";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

const PageChange = styled.div`
  text-align: center;
`;

const Wrap = styled.div`
  height: 100%;
`;

const ArrowLeft = styled(AiOutlineArrowLeft)`
  cursor: pointer;
`;
const ArrowRight = styled(AiOutlineArrowRight)`
  cursor: pointer;
`;

const WrapedDocument = styled(Document)`
  line-height: 1;
  border: 1px solid black;
`;

export default function PdfDocument({ file }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, [file]);

  const removeTextLayerOffset = () => {
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    );
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.top = "0";
      style.left = "0";
      style.transform = "";
    });
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const moveForward = () => {
    if (pageNumber < numPages) setPageNumber(pageNumber + 1);
  };

  const moveBack = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  return (
    <Wrap>
      <WrapedDocument
        file={`data:application/pdf;base64,${file}`}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} onLoadSuccess={removeTextLayerOffset} />
      </WrapedDocument>
      <PageChange>
        <div>
          <ArrowLeft onClick={moveBack} /> Page {pageNumber} of {numPages}{" "}
          <ArrowRight onClick={moveForward} />
        </div>
      </PageChange>
    </Wrap>
  );
}
