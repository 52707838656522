import {
  EDUCATION_REMOVE_EDU_FROM_ARRAY,
  EDUCATION_SET_EDU_ARRAY,
} from "../Actions/Actions";

const initialState = {
  Education: [{ eduId: 0, eduYear: "", eduDiploma: "" }],
};

const educationReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDUCATION_REMOVE_EDU_FROM_ARRAY:
      return {
        ...state,
        Education: action.payload.data,
      };
    case EDUCATION_SET_EDU_ARRAY:
      return {
        ...state,
        Education: action.payload.data,
      };

    default:
      return state;
  }
};

export default educationReducer;
