export const isRegexExactMatch = (value, regexp) => {
  const res = value.match(regexp);
  return res && res[0] && res[0] === res.input;
};

export const isLinkedinProfileUrl = (value) => {
  const linkedInProfileURLRegExp =
    "(https?:\\/\\/(www.)?linkedin.com\\/(mwlite\\/|m\\/)?in\\/[a-zA-Z0-9_.-]+\\/?)";
  return !!isRegexExactMatch(value, linkedInProfileURLRegExp);
};
