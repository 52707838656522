import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import styled from "styled-components";
import { setStep } from "../../../Redux/Actions/Actions";
import { connect } from "react-redux";

const Text = styled.span`
  top: -65px;
  position: relative;
`;
const theme = createMuiTheme({
  overrides: {
    MuiStepIcon: {
      root: {
        "&$active": {
          color: "#2ecc71",
        },
        "&$completed": {
          color: "#2ecc71",
        },
        cursor: "pointer",
      },
      active: {},
      completed: {},
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Experiences",
    "Education",
    "Languages",
    "Skills",
    "Hobbies",
    "Generation",
  ];
}

function HorizontalLabelPositionBelowStepper({
  StepNumber,
  setStep,
  onClick,
  hobbies,
  isUpdate,
}) {
  const classes = useStyles();
  const [activeStep] = React.useState(StepNumber);
  const steps = getSteps();

  const stepClick = (index) => {
    if (isUpdate) {
      setStep(index + 2);
      if (!hobbies) {
        onClick();
      }
    } else {
      if (index - 1 < activeStep) {
        setStep(index + 2);
        if (!hobbies) {
          onClick();
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step onClick={() => stepClick(index)} key={label}>
              <StepLabel className={classes.stepLabel}>
                <Text>{label}</Text>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </ThemeProvider>
    </div>
  );
}
HorizontalLabelPositionBelowStepper.propTypes = {
  StepNumber: PropTypes.number.isRequired,
};
HorizontalLabelPositionBelowStepper.defaultProps = {
  StepNumber: 0,
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStep: (data) => dispatch(setStep(data)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(HorizontalLabelPositionBelowStepper);
