import AuthController from '../AuthProvider/AuthController';

class CvService {
  async sendNoteToCrm(file) {
    const token = await AuthController.getToken();

    let response = await fetch(process.env.REACT_APP_API_URL + "/api/note", {
      headers: {
        Authorization: "Bearer " + token.idToken.rawIdToken,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(file),
    });

    let responseCode = response.status;

    return { responseCode };
  }
  async getCommercialContacts() {
    const token = await AuthController.getToken();
    let result = await fetch(process.env.REACT_APP_API_URL + "/api/contacts", {
      headers: {
        Authorization: "Bearer " + token.idToken.rawIdToken,
      },
      method: "GET",
    });
    let responseCode = result.status;
    if (responseCode >= 400) {
      result = {};
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }
  //
  // SEND Cv to store it
  //
  async sendToSharepoint(model) {
    const token = await AuthController.getToken();
    let response = await fetch(process.env.REACT_APP_API_URL + "/api/file", {
      headers: {
        Authorization: "Bearer " + token.idToken.rawIdToken,
      },
      method: "POST",
      body: model,
    });
    let responseCode = response.code;

    return { responseCode };
  }
  //
  // GET File in pdf format
  //
  async getFile(filename, id) {
    const token = await AuthController.getToken();
    let response = await fetch(
      process.env.REACT_APP_API_URL +
        "/api/file?filename=" +
        filename +
        "&id=" +
        id,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        method: "GET",
      }
    );
    let file;
    let code = response.status;
    if (code >= 400) {
      file = null;
    } else {
      response = await response.json();
      file = response;
    }

    return { file, code };
  }
}

export default new CvService();
