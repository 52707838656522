import AuthController from '../AuthProvider/AuthController';

class EducationService {
  //
  // Get Education
  //
  async get(id) {
    const token = await AuthController.getToken();
    let result = await fetch(
      process.env.REACT_APP_API_URL + "/api/education?careerid=" + id,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        method: "POST",
      }
    );
    let responseCode = result.status;
    if (responseCode >= 400) {
      result = null;
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }

  //
  // Send model to CRM
  //
  async send(model) {
    const token = await AuthController.getToken();
    let result = await fetch(
      process.env.REACT_APP_API_URL + "/api/career/education",
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(model),
      }
    );
    let responseCode = result.status;
    if (responseCode >= 400) {
      result = null;
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }
  //
  // Remove experience from crm
  //
  async remove(id) {
    const token = await AuthController.getToken();

    let result = await fetch(
      process.env.REACT_APP_API_URL + "/api/career/education/" + id,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        method: "DELETE",
      }
    );
    let responseCode = result.status;
    if (responseCode >= 400) {
      result = null;
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }
  //
  // Update existing model in crm
  //
  async update(model, id) {
    const token = await AuthController.getToken();
    let result = await fetch(
      process.env.REACT_APP_API_URL + "/api/career/education/" + id,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
          "Content-Type": "application/json",
        },
        method: "PATCH",
        body: JSON.stringify(model),
      }
    );
    let responseCode = result.status;
    if (responseCode >= 400) {
      result = null;
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }
  //
  // Scrap data from CV
  //
  async scrap(data, code) {
    const token = await AuthController.getToken();

    let result = await fetch(
      process.env.REACT_APP_API_URL + "/api/education?code=" + code,
      {
        headers: {
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        method: "POST",
        body: data,
      }
    );

    let responseCode = result.status;
    if (responseCode >= 400) {
      result = null;
    } else {
      result = await result.json();
    }
    return { result, responseCode };
  }
}

export default new EducationService();
