import {
  GENERATOR_CV_CHANGE,
  GENERATOR_LINKEDIN_CHANGE,
  GENERATOR_LANGUAGE_CHANGE,
  GENERATOR_CV_PDF_CHANGE,
  GENERATOR_SET_SCRAPED_CV_DATA,
  GENERATOR_CV_OUTPUT_LANGUAGE_CHANGE,
  GENERATOR_CV_TIMESTAMP,
} from "../Actions/Actions";

const initialState = {
  cvFileName: null,
  cvFile: null,
  cvFilePdf: null,
  cvTimeStamp: null,
  cvLanguage: "English",
  cvOutputLanguage: "",
  linkedIn: "",
};

const cvGenerator = (state = initialState, action) => {
  switch (action.type) {
    case GENERATOR_CV_CHANGE: {
      return {
        ...state,
        cvFile: action.payload.data.source,
        cvFileName: action.payload.data.name,
      };
    }
    case GENERATOR_LINKEDIN_CHANGE: {
      return {
        ...state,
        linkedIn: action.payload.data,
      };
    }
    case GENERATOR_LANGUAGE_CHANGE: {
      return {
        ...state,
        cvLanguage: action.payload.data,
      };
    }
    case GENERATOR_CV_PDF_CHANGE: {
      return {
        ...state,
        cvFilePdf: action.payload.data,
      };
    }
    case GENERATOR_SET_SCRAPED_CV_DATA: {
      return {
        ...state,
        scrapedData: action.payload.data,
      };
    }
    case GENERATOR_CV_OUTPUT_LANGUAGE_CHANGE: {
      return {
        ...state,
        cvOutputLanguage: action.payload.data,
      };
    }
    case GENERATOR_CV_TIMESTAMP: {
      return {
        ...state,
        cvTimeStamp: action.payload.data,
      };
    }
    default:
      return state;
  }
};

export default cvGenerator;
