import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
})(TextField);

const BlackInput = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
})(TextField);

const Input = ({
  handleBlur,
  value,
  valueChange,
  error,
  variant,
  label,
  black,
  helperText,
  disabled,
  rows,
  multiline,
  name,
  small,
  inputProps,
  setFieldValue,
}) => {
  return black ? (
    <CssTextField
      onBlur={handleBlur}
      name={name}
      label={label}
      variant={variant}
      value={value}
      onChange={valueChange}
      error={error}
      helperText={helperText}
      disabled={disabled}
      inputProps={inputProps}
    />
  ) : (
    <BlackInput
      onBlur={handleBlur}
      name={name}
      size={small ? "small" : "medium"}
      label={label}
      variant={variant}
      value={value}
      onChange={valueChange}
      error={error}
      fullWidth={true}
      disabled={disabled}
      rowsMax={rows}
      multiline={multiline}
      inputProps={inputProps}
    />
  );
};

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  valueChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  variant: PropTypes.string,
  label: PropTypes.string,
  black: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  multiline: PropTypes.bool,
  inputProps: PropTypes.any,
};
export default Input;
