import styled from "styled-components";
import { BsThreeDotsVertical } from "react-icons/bs";

export const Dots = styled(BsThreeDotsVertical)`
  color: black;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: 400px;
  transform: translate(-50%, -50%);
`;

export const Wraper = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 100%;
  min-height: 600px;
  margin-top: 50px;
`;

export const ErrorMessage = styled.div`
  width: 100%;
  color: red;
  font-size: 18px;
  text-align: center;
`;

export const StyleForm = styled.div`
  position: relative;
  width: ${({ full }) => (full ? 100 : 55)}%;

  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const FormFields = styled.div`
  height: 75%;
  width: 100%;
`;

export const Cv = styled.div`
  width: 45%;
  position: fixed;
  height: 80%;
  position: sticky;
  top: 0;
`;
export const ButtonWraper = styled.div`
  height: 5%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const FormLineWraper = styled.div`
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;

export const InputWraper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  width: ${({ width }) => width}%;
`;
