import React from "react";
import styled from "styled-components";

const Wraper = styled.div`
  margin-top: 80px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

const Text = styled.h1`
  font-size: 25px;
  color: #2ecc71;
  text-align: center;
`;

export default function NotFoundPage() {
  return (
    <Wraper>
      <Text>
        Page not found. We can't find career with that ID or action type
      </Text>
    </Wraper>
  );
}
