import { IDENTITY_UPDATE_USER_INFO } from "../Actions/Actions";

const initialState = {
  businessUnitId: "",
  email: "",
  firstName: "",
  lastName: "",
};

const Identity = (state = initialState, action) => {
  switch (action.type) {
    case IDENTITY_UPDATE_USER_INFO: {
      const {
        businessUnitId,
        email,
        firstName,
        lastName,
      } = action.payload.data;
      return {
        ...state,
        businessUnitId,
        email,
        firstName,
        lastName,
      };
    }
    default:
      return state;
  }
};

export default Identity;
